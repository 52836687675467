import React from 'react'
import Header from '../welcome/Header'
import { LastNews } from './LastNews'
import { Footer } from '../welcome/Footer'

export const Blog = () => {
  return (
    <>
        <Header />
        <LastNews /> 
        <Footer />
    </>
  )
}
