import  secureLocalStorage  from  "react-secure-storage";

const prefix_session = 'auth_';

export const AuthSession_stringStoreData =  (key,value) => {
    try {
      const stores = allStorage();
       secureLocalStorage.setItem(prefix_session+key, JSON.stringify(value))
      console.log('success save at key '+key, value)

    } catch (e) {
      // saving error
      console.log('error save at key '+key, e)
    }
}

export const AuthSession_objectStoreData =  (key,value) => {
    try {
      const jsonValue = JSON.stringify(value)
       secureLocalStorage.setItem(prefix_session+key, jsonValue)
      console.log('success save at key '+key, value)
    } catch (e) {
      // saving error
      console.log('error save at key '+key, e)
    }
}



export const AuthSession_stringGetData = async (key) => {
    try {
      const value = await secureLocalStorage.getItem(prefix_session+key)
      if(value !== null) {
        // value previously stored
        return value.replace('"','').replace('"','');
      }
    } catch(e) {
      // error reading value
      console.log('error get at key '+key, e)
    }
}


export const AuthSession_objectGetData = async (key) => {
    try {
      const jsonValue = await secureLocalStorage.getItem(prefix_session+key)
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch(e) {
      // error reading value
      console.log('error get at key '+key, e)
    }
}
  

export const AuthSession_RemoveAll = async () => {
  try {
      const stores = allStorage();
      stores.map((result, i, store) => {

          if((store[i]).includes(prefix_session)){
                localStorage.removeItem(store[i])
          }
          
          
          return true;
      });

  } catch (e) {
      // error reading value
      console.log("error getData session " + prefix_session);
      return "";
  }
}



export const removeAllSession = async () => {
  try {

      const stores = allStorage();
      stores.map((result, i, store) => {
            secureLocalStorage.removeItem(store[i][0])
        
        return true;
      });
  } catch (e) {
      // error reading value
      console.log("error getData session " + prefix_session);
      return "";
  }
}


function allStorage() {

  var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
      
  while ( i-- ) {
      values.push( keys[i]  );
  }

  return values;
}