import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export const MyDropdownLI = ({children, title}) => {
    const [my_class, set_my_class] = useState('');

    const onClick = () => {
        if ( my_class == '') {
            set_my_class('dropdown-active X');
        } else {
            set_my_class('');
        }
    }
  return (
    <li className="dropdown" onClick={onClick} ><Link href="#" className='nav-link'>{title} <i className="bi bi-chevron-down" /></Link>
        <ul className={`${my_class}  nyenyenye`}>
            {children}
        </ul>
    </li>
  )
}
