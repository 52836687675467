import React from 'react'
import { item2007, item2008, item2009, item2011, item2023, mile, mileline, mileline2 } from '../assets/img/ImageConstants'

const ItemWidth = 100;

export const MileStone = () => {
  return (
    <div className='container pb-5 mb-5' style={{height:'auto'}}>
        <h3 className='text-center text-white mt-5 mb-5'>MILESTONE</h3>

        <div className='row justify-content-center'>
            <div className='col-6 col-sm-6 col-md-12'>

                {/* MILESTONE 1 */}
                <div className='row'
                    data-aos="fade" data-aos-duration="1200"
                >
                    <div className='col d-none d-md-block'>
                        <div>
                            {/* - Memulai awal bisnis di bidang system */}
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            style={{
                                width: ItemWidth,
                                position: 'relative',
                            }}
                        >
                            <img src={mile} className='img-fluid' />
                        </div>
                    </div>

                    <div className='col d-none d-md-block'>
                        <div className='row align-items-center'
                            style={{
                                position: 'absolute',
                                marginTop: -30
                            }}
                        >
                            <div className='col-4'>
                                <h3 className='text-white'>2004</h3>
                            </div>
                            <div className='col'>
                                <div className='card card-body' style={{ 
                                    backgroundColor: '#3260D0'
                                }}>
                                    <span className='text-white' style={{ fontSize: 12,}}>
                                        PT Remala Abadi didirikan yang bergerak <br></br>
                                        dibidang perdagangan dan jasa.
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                {/* LINE2 */}
                <div className='row'
                    data-aos="fade" data-aos-duration="1000"
                >
                    <div className='col d-none d-md-block'>
                        <div>
                        {/* - Memulai awal bisnis di bidang system */}
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            style={{
                                width: ItemWidth,
                                position: 'relative',
                            }}
                        >
                            <img src={mileline2} className='img-fluid' 
                                style={{
                                    width: 'inherit',
                                    position: 'absolute',
                                    left: 70,
                                    top: -40,
                                }} 
                            />
                        </div>
                    </div>
                    <div className='col'>

                    </div>
                </div>

                {/* milestone */}
                <div className='row'
                    data-aos="fade" data-aos-duration="1200"
                    style={{
                        position: 'relative',
                        height: 150,
                    }}
                >
                    <div className='col d-none d-md-block'>
                        <div className='row align-items-center'
                            style={{
                                position: 'absolute',
                                bottom: 0,
                            }}
                        >
                            <div className='col'>
                                <div className='card card-body' style={{ 
                                    backgroundColor: '#3260D0'
                                }}>
                                    <span className='text-white' style={{ fontSize: 12,}}>
                                    - Memulai awal bisnis di bidang system integrator, IT dan Networks solustion. <br></br>
                                    - Mengembangkan infrastruktur jaringan dengan media wireless. <br></br>
                                    - Mendapat kepercayaan dari berbagai perusahaan besar layanan solusi integrasi berbasis IP. <br></br>
                                    </span>
                                </div>
                            </div>
                            <div className='col-4'>
                                <h3 className='text-white'>2007</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            style={{
                                width: ItemWidth,
                                position: 'absolute',
                                bottom: 0,
                                marginLeft: 140,
                            }}
                        >
                            <img src={mile} className='img-fluid' />
                            <img src={item2007} className='img-fluid' 
                                style={{
                                    width: 'inherit',
                                    position: 'absolute',
                                    left: 0,
                                    top: -50,
                                }} 
                            />
                        </div>
                    </div>
                    <div className='col'>

                    </div>
                </div>
               
                {/* line 1 */}
                <div className='row'
                    data-aos="fade" data-aos-duration="1000"
                >
                    <div className='col d-none d-md-block'>
                        <div>
                        {/* - Memulai awal bisnis di bidang system */}
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            style={{
                                width: ItemWidth,
                                position: 'relative',
                            }}
                        >
                            <img src={mileline} className='img-fluid' 
                                style={{
                                    width: 'inherit',
                                    position: 'absolute',
                                    left: 70,
                                    top: -40,
                                }} 
                            />
                        </div>
                    </div>
                    <div className='col'>

                    </div>
                </div>

                {/* milestone */}
                <div className='row'
                    data-aos="fade" data-aos-duration="1200"
                     style={{
                        position: 'relative',
                        height: 150,
                    }}
                >
                    <div className='col d-none d-md-block'>
                        <div>
                        {/* - Memulai awal bisnis di bidang system */}
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            style={{
                                width: ItemWidth,
                                position: 'absolute',
                                bottom: 0,
                                marginLeft: 0,
                            }}
                        >
                            <img src={mile} className='img-fluid' />
                            <img src={item2008} className='img-fluid' 
                                style={{
                                    width: 'inherit',
                                    position: 'absolute',
                                    left: 0,
                                    top: -30,
                                }} 
                            />
                        </div>
                    </div>
                    <div className='col d-none d-md-block'>
                        <div className='row align-items-center'
                            style={{
                                position: 'absolute',
                                bottom: 0,
                            }}
                        >
                            <div className='col-3'>
                                <h3 className='text-white'>2008</h3>
                            </div>
                            <div className='col'>
                                <div className='card card-body' style={{ 
                                    backgroundColor: '#3260D0'
                                }}>
                                    <span className='text-white' style={{ fontSize: 12,}}>
                                    - Mengembangkan jangkauan layanan. <br></br>
                                    e-JABODETABEK. <br></br>
                                    - Dipercaya oleh para pengusaha internet <br></br>
                                    cafe sebagai ISP dengan koneksi yang stabil <br></br>
                                    </span>
                                </div>
                            </div>
                            {/* <div className='col-1'></div> */}
                            
                        </div>
                    </div>
                </div>

                {/* LINE2 */}
                <div className='row'
                    data-aos="fade" data-aos-duration="1000"
                >
                    <div className='col d-none d-md-block'>
                        <div>
                        {/* - Memulai awal bisnis di bidang system */}
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            style={{
                                width: ItemWidth,
                                position: 'relative',
                            }}
                        >
                            <img src={mileline2} className='img-fluid' 
                                style={{
                                    width: 'inherit',
                                    position: 'absolute',
                                    left: 70,
                                    top: -40,
                                }} 
                            />
                        </div>
                    </div>
                    <div className='col'>

                    </div>
                </div>

                 {/* milestone */}
                 <div className='row'
                    data-aos="fade" data-aos-duration="1200"

                     style={{
                        position: 'relative',
                        height: 150,
                    }}
                >
                    <div className='col d-none d-md-block'>
                        <div className='row align-items-center'
                            style={{
                                position: 'absolute',
                                bottom: 20,
                            }}
                        >
                            <div className='col'>
                                <div className='card card-body' style={{ 
                                    backgroundColor: '#3260D0'
                                }}>
                                    <span className='text-white' style={{ fontSize: 12,}}>
                                        Mengembangkan infrastruktur jaringan fiber optik sebagai solusi backbone disetiap BTS.
                                    </span>
                                </div>
                            </div>
                            <div className='col-4'>
                                <h3 className='text-white'>2009</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            style={{
                                width: ItemWidth,
                                position: 'absolute',
                                bottom: 0,
                                marginLeft: 140,
                            }}
                        >
                            <img src={mile} className='img-fluid' />
                            <img src={item2009} className='img-fluid' 
                                style={{
                                    width: 'inherit',
                                    position: 'absolute',
                                    left: 0,
                                    top: -50,
                                }} 
                            />
                        </div>
                    </div>
                    <div className='col'>

                    </div>
                </div>

                {/* line 1 */}
                <div className='row'
                    data-aos="fade" data-aos-duration="1000"
                >
                    <div className='col d-none d-md-block'>
                        <div>
                        {/* - Memulai awal bisnis di bidang system */}
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            style={{
                                width: ItemWidth,
                                position: 'relative',
                            }}
                        >
                            <img src={mileline} className='img-fluid' 
                                style={{
                                    width: 'inherit',
                                    position: 'absolute',
                                    left: 70,
                                    top: -40,
                                }} 
                            />
                        </div>
                    </div>
                    <div className='col'>

                    </div>
                </div>

                {/* milestone */}
                <div className='row'
                    data-aos="fade" data-aos-duration="1200"
                     style={{
                        position: 'relative',
                        height: 150,
                    }}
                >
                    <div className='col d-none d-md-block'>
                        <div>
                        {/* - Memulai awal bisnis di bidang system */}
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            style={{
                                width: ItemWidth,
                                position: 'absolute',
                                bottom: 0,
                                marginLeft: 0,
                            }}
                        >
                            <img src={mile} className='img-fluid' 
                            />
                            <img src={item2011} className='img-fluid' 
                                style={{
                                    width: 'inherit',
                                    position: 'absolute',
                                    left: 0,
                                    top: -80,
                                }} 
                            />
                        </div>
                    </div>
                    <div className='col d-none d-md-block'>
                        <div className='row align-items-center'
                            style={{
                                position: 'absolute',
                                bottom: 0,
                            }}
                        >
                            <div className='col-3'>
                                <h3 className='text-white'>2011</h3>
                            </div>
                            <div className='col'>
                                <div className='card card-body' style={{ 
                                    backgroundColor: '#3260D0'
                                }}>
                                    <span className='text-white' style={{ fontSize: 12,}}>
                                    Pengembangan layanan Internet dedicated <br></br>
                                    dan peningkatan kapasitas bandwidth seluruh. <br></br>
                                    pelanggan.<br></br>
                                    </span>
                                </div>
                            </div>
                            {/* <div className='col-1'></div> */}
                            
                        </div>
                    </div>
                </div>


                {/* LINE2 */}
                <div className='row'
                    data-aos="fade" data-aos-duration="1000"
                >
                    <div className='col d-none d-md-block'>
                        <div>
                        {/* - Memulai awal bisnis di bidang system */}
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            style={{
                                width: ItemWidth,
                                position: 'relative',
                            }}
                        >
                            <img src={mileline2} className='img-fluid' 
                                style={{
                                    width: 'inherit',
                                    position: 'absolute',
                                    left: 70,
                                    top: -40,
                                }} 
                            />
                        </div>
                    </div>
                    <div className='col'>

                    </div>
                </div>

                 {/* milestone */}
                 <div className='row'
                    data-aos="zoom-in" data-aos-duration="1200"
                     style={{
                        position: 'relative',
                        height: 150,
                    }}
                >
                    <div className='col d-none d-md-block'>
                        <div className='row align-items-center'
                            style={{
                                position: 'absolute',
                                bottom: 20,
                            }}
                        >
                            <div className='col-4'>
                                <div className='card card-body' style={{ 
                                    backgroundColor: '#3260D0'
                                }}>
                                    <span className='text-white' style={{ fontSize: 12,}}>
                                    Customer yang terus bertambah dan
                                    dipercacya oleh berbagai sekmen
                                    khususnya Bisnis, Corporate dan
                                    Pemerintah sebagai ISP terbaik.
                                    </span>
                                </div>
                            </div>
                            <div className='col-6'>
                                <h3 className='text-white'>2012 - 2023</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-3 on-center text-center'> 
                        <div
                            
                            style={{
                                width: ItemWidth,
                                position: 'absolute',
                                bottom: 0,
                                marginLeft: 140,
                            }}
                        >
                            <img src={mile} className='img-fluid' />
                            <img src={item2023} className='img-fluid' 
                                style={{
                                    width: 'inherit',
                                    position: 'absolute',
                                    left: 0,
                                    top: -70,
                                }} 
                            />
                        </div>
                    </div>
                    <div className='col'>

                    </div>
                </div>
                
            </div>
        </div>
        
    
      </div>
  )
}
