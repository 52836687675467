import React from 'react'

const color = '#1c26c8';

const Card = ({
    title,
}) => {
    return (
        <div className='text-white ms-3 me-3 text-center mb-5' data-aos="fade">
            <h4 className='fw-bold'>{title}</h4>
            <div className='card p-5' style={{ background: color }}>
                <h5 className='text-center text-white'>
                    Internet & Solusi yang utama di Indonesia
                    Menjadi Penyedia Layanan
                    (Ultimate Internet Solution)
                </h5>
            </div>
        </div>
    )
}

export const Header = () => {
  return (
    <div className='container mt-5'>
        <h3 className='text-center text-white fw-bold' data-aos="fade">SOLUSI INTERNET TERBAIK UNTUK KEBUTUHAN ANDA</h3>
        <p className='text-center text-white h5 mb-100' data-aos="fade">
            Dengan kemampuan kami, serta dukungan yang maksimal dari seluruh mitra-kerja kami, maka kami yakin bahwa
            PT Remala Abadi akan mampu memberikan pelayanan yang maksimal dengan biaya yang jauh lebih kompetitif.
            PT. Remala Abadi berniat untuk tumbuh bersama dengan mitra-kerja, pelanggan, dan karyawan.
        </p>

        <div className='d-flex justify-content-center mb-100'>
            <div className='col-md-8' data-aos="fade">
                <div className='card card-body text-white ' style={{ background: color, }}>
                    <h4 className=' fw-bold'>History</h4>
                    <p>
                        Tachyon Adalah salah satu Internet Service Provider
                        yang mana telah berdiri sejak tahun 2007 dibawah manajemen PT. Remala Abadi.
                        Kami melayanai kebutuhan Akses Internet, Network & IT Solution yang menjadikan sebuah layanan
                        Managed Services yang didukung dengan sumber daya manuasia yang profesional dan dukungan
                        infrastruktur jaringan yang handal yang berupa jaringan Fiber Optic maupun Wireless yang
                        menggunakan teknologi terbaru. Dengan selalu berfikir kreatif dan inovatif serta yakin dan optimis
                        untuk dapat memberikan layanan serta solusi yang tepat, handal dan tentunya efektif dan efisien. 
                        <br/><br/>
                        Perusahaan kami telah berpengalaman melayani kebutuhan layanan Telekomunikasi dan Internet di
                        berbagai jenis usaha, baik di segmen Korporasi, Industri Telekomunikasi, Pemerintahan, Kementerian
                        dan Lembaga, Perbankan, Pariwisata, Pendidikan, dll.
                        <br/><br/>
                        Berbekal pengalaman dibidang telekomunikasi serta jaringan internet Tachyon Network Indonesia terus
                        melakukan pengembangan layanan untuk memenuhi semua kebutuhan customer yang semakin dinamis,
                        dengan kami bekerja bersama group dan partner perusahaan, kami bersinergi dalam pengembangan
                        produk / layanan-layanan kami. Adapun group perusahaan kami saat ini meliputi beberapa perusahaan :
                    </p>
                </div>
            </div>
        </div>

        <div className='row justify-content-center'>
            <div className='col-md-6'>
                <Card 
                    title={'VISION'}
                />
            </div>

            <div className='col-md-6'>
                <Card 
                    title={'MISSION'}
                />
            </div>
        </div>
    </div>
  )
}
