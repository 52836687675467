import React from 'react'

export default function CallNow() {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{ height: 100, background: '#135EA6'}}>
        <div className='me-5'>
            <span className='text-light h4 fw-bold'>Find out how we can help you</span>
        </div>
        <div>
            <button className='btn btn-light' style={{ borderRadius: 30}}>Call Now</button>
        </div>
    </div>
  )
}
