import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <>
        {/* ======= Footer ======= */}
        <footer id="footer">
        <div className="footer-top">
            <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                <div className="footer-info text-start">
                    <h3>PT REMALA ABADI</h3>
                    <b className="h5"><em>Graha Mustika Ratu</em></b>
                    <p className='text-left'>
                    Jl Gatot Subroto No.74-75 Lantai 10, Jakarta,
                    DKI Jakarta 12870
                    </p>
                    <p>
                    <br />
                    <strong>Phone:</strong> (021) 83709269<br />
                    <strong>Email:</strong> noc@tachyon.net.id<br />
                    </p>
                    <div className="social-links mt-3 text-center">
                        <Link href="https://www.instagram.com/tachyondesk/" className="instagram"><i className="bx bxl-instagram" /></Link>
                        <Link href="https://www.linkedin.com/company/pt-remala-abadi/" className="linkedin"><i className="bx bxl-linkedin" /></Link>
                        <Link to="#" className="twitter"><i className="bx bxl-twitter" /></Link>
                        <Link to="#" className="facebook"><i className="bx bxl-facebook" /></Link>
                        <Link to="#" className="google-plus"><i className="bx bxl-skype" /></Link>
                    </div>
                </div>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                <h4>LAYANAN KAMI</h4>
                <ul>
                    <li><i className="bx bx-chevron-right" /> <Link to="/internet-dedicated">Internet Dedicated</Link></li>
                    <li><i className="bx bx-chevron-right" /> <Link to="/internet-broadband">Internet Broadband</Link></li>
                    <li><i className="bx bx-chevron-right" /> <Link to="/managed-service">Manage Service</Link></li>
                </ul>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                <h4>Support & Help</h4>
                <ul>
                    <li><i className="bx bx-chevron-right" /> <Link to="#">Panduan</Link></li>
                    <li><i className="bx bx-chevron-right" /> <Link to="#">Tanya Jawab</Link></li>
                    <li><i className="bx bx-chevron-right" /> <Link to="#">Blog/News</Link></li>
                </ul>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                <h4>INFORMASI</h4>
                <ul>
                    <li><i className="bx bx-chevron-right" /> <Link to="#">About Us</Link></li>
                    <li><i className="bx bx-chevron-right" /> <Link to="#">Contact Us</Link></li>
                    <li><i className="bx bx-chevron-right" /> <Link to="#">Careert</Link></li>
                    <li><i className="bx bx-chevron-right" /> <Link to="#">Coverage Area</Link></li>
                    <li><i className="bx bx-chevron-right" /> <Link to="#">Speedtest</Link></li>
                </ul>
                </div>
                
            </div>
            </div>
        </div>
        <div className="container">
            <div className="copyright">
            © Copyright <strong><span>PT Remala Abadi</span></strong>. All Rights Reserved
            </div>
        
        </div>
        </footer>
        {/* End Footer */}

    </>
  )
}
