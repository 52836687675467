import React, { useEffect, useState } from 'react'

export const ModalStaticBackdrop = ({ isShow, handleShow, modal_title, children }) => {
    const [is_show, set_is_show] = useState(false);

    useEffect( () => {
        showMe(isShow);
    }, [isShow])

    const showMe = (data) => {
        set_is_show(data);
    }

    const handleClose = ( ) => {
        set_is_show(false);
        handleShow(false);
    }


  return (
    <>
        {/* Modal */}
        <div className={`modal fade `+(is_show == true? 'show' : '')} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel"
         style={{display: (is_show == true? 'block' : 'none'), background: 'rgba(0,0,0,0.2)'}}
         aria-hidden="true" role={(is_show == true? 'dialog' : '')}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">{modal_title} </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" 
                            onClick={handleClose}
                        />
                    </div>
                    <div className="modal-body container">
                        {children}
                    </div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            onClick={handleClose}
                        >Close</button>
                        <button type="button" className="btn btn-primary">Understood</button>
                    </div> */}
                </div>
            </div>
        </div>
    </>
  )
}
