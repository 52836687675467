import React, { useEffect } from "react";
import { AuthSession_RemoveAll, AuthSession_objectGetData, AuthSession_stringGetData } from "../helper/session/AuthSession";

const AuthContext = React.createContext(null);

// This hook can be used to access the user info.
export function useAuth() {
  return React.useContext(AuthContext);
}


export function AuthProvider(props) {

  const [user, setAuth] = React.useState(null);
  const [user_data, set_user_data] = React.useState(null);

  useEffect( () => {
    getSession();
  }, [])

  const getSession = async () => {

    const partnerData = await AuthSession_objectGetData('partnerData');
    const token = await AuthSession_stringGetData('token');
    if ( token ) {
      set_user_data(partnerData[0]);
      setAuth(token)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signIn: () => setAuth('ok'),
        signOut:  () => {
            localStorage.clear();
            AuthSession_RemoveAll();
            setAuth(null);
        },
        user_data,
        user,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}