import React from 'react'
import './welcome.css';
import NavBar2 from '../../components/NavBar2';
import Carousel from './Carousel';
import LayananKami from './LayananKami';
import WeAreReady from './WeAreReady';
import CallNow from './CallNow';
import Footer from './Footer';
import NavBar3 from '../../components/NavBar3';

export const CardLayanan = ({ title, img_url, body}) => {
    return (
        <div className='col-md-3 mb-3'>
            <div className='text-center'>
                <div className='text-center row'>
                    <span className='fw-bold text-dark mb-2 '>{title}</span>
                </div>
                <view className='img-container '>
                    <img src={img_url} alt='internet dedicated' className='img-fluid' style={{height: 200, width: '100%'}} />
                </view>
                <view className='text-start p-3 card shadow' 
                    style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                        borderBottomRightRadius: 120,
                        borderBottomLeftRadius: 50,
                        marginTop: -20,
                        height: 230,
                    }}
                >
                    <span className='fw-normal text-dark' style={{ fontSize: 14}} >
                       {body}
                    </span>
                    
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 20,
                        }}
                    >

                        <button className='btn btn-sm btn-primary'
                            style={{
                                width: 100,
                                borderRadius: 50,
                            }}
                        ><span style={{ fontSize: 12}}>Lebih lanjut</span></button>
                    </div>
                </view>
            </div>
        </div>
    )
}

export default function Welcome() {
  return (
    <div>
        <NavBar3 />
        <Carousel />
        <LayananKami />
        <WeAreReady />
        <CallNow />
        <Footer />
    </div>
  )
}
