import React from 'react'
import { FaBeer, FaFacebook, FaInstagram, FaLinkedin, FaSearch, FaUserAlt, FaYoutube } from 'react-icons/fa';

export default function Footer() {
  return (
    <div className='container my-5'>
        <div className='row align-items-center'>
            <div className='col-md-2 text-center'>
                <img src="assets/img/my-logo.png" alt="logo" class="img-fluid" style={{ maxWidth: 150}}  />
            </div>
            <div className='col-md-5'
            >
                <div className='fw-bold'>Tentang</div>
                <p  style={{ fontSize: 14,}}>
                    Tachyon Adalah salah satu Internet Service Provider yang mana telah berdiri sejak tahun 2007 dibawah manajemen PT. Remala Abadi. Kami melayanai kebutuhan Akses Internet, Network & IT Solution yang menjadikan sebuah layanan Managed Services yang didukung dengan sumber daya manusia yang profesional dan dukungan infrastruktur jaringan yang handal yang berupa jaringan Fiber Optic maupun Wireless yang menggunakan teknologi terbaru.
                </p>
            </div>
            <div className='col-md-2'>
                <div className='fw-bold'>Tautan Penting</div>
                
                <a href='#' className='d-block' style={{ fontSize: 14}} >PT Remala Abadi</a>
                <a href='#' className='d-block' style={{ fontSize: 14}} >Pengumuman</a>
                <a href='#' className='d-block' style={{ fontSize: 14}} >Laporan</a>
                <a href='#' className='d-block' style={{ fontSize: 14}} >Berita</a>
                <a href='#' className='d-block' style={{ fontSize: 14}} >Investor</a>
                <a href='#' className='d-block' style={{ fontSize: 14}} >Management</a>
            </div>
            <div className='col-md-3'>
                <div className='fw-bold'>Graha Mustika Ratu</div>
                <p>
                    Jl Gatot Subroto No.74-75 Lantai 10, Jakarta, <br/>
                    DKI Jakarta 12870 <br/>
                    Phone: (021) 83709269 <br/>
                    Email: noc@tachyon.net.id
                </p>
                <div className='d-flex'>
                    <div className='container-icon-sc'>
                        <FaLinkedin color='white' size={35} style={{ backgroundColor: '#135EA6', padding: 5, borderRadius: 100}} />
                    </div>
                    <div className='container-icon-sc'>
                        <FaFacebook color='white' size={35} style={{ backgroundColor: '#135EA6', padding: 5, borderRadius: 100}}  />
                    </div>
                    <div className='container-icon-sc'>
                        <FaInstagram color='white' size={35} style={{ backgroundColor: '#135EA6', padding: 5, borderRadius: 100}}  />
                    </div>
                    <div className='container-icon-sc'>
                        <FaYoutube color='white' size={35} style={{ backgroundColor: '#135EA6', padding: 5, borderRadius: 100}}  />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
