import React from 'react'

export const Hero = () => {
  return (
    <>
       <div className="row mt-5">
            
            <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
                 <div className="section-title" data-aos="fade-up">
                    <p className='fs-4'>Pertanyaan yang sering diajukan</p>
                </div>
            </div>
            <div className="col-xl-5 col-lg-7 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
            <img src="assets/img/faq-pic.svg" className="img-fluid" alt="" />
            </div>
        </div>
    </>
  )
}
