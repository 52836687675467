import React from 'react'

import './Content.css'

export const Content = () => {
  return (
    <div className='container'>
        <div className='header'>
            <h5 className='text-dark fw-bold'>Apa itu ? Manage Service</h5>
            {/* <span>Layanan Internet dengan biaya lebih terjangkau</span> <br></br>
            <span>dengan dukungan fiber optic dan teknologi telekomunikasi terbaru yang bersifat</span> <br></br>
            <span>shared network dan mampu menyediakan jaringan internet cepat dengan bandwidth broadband/Upton</span> <br></br>
            <span>dengan pembagian serta perbandingan yang terukur sehingga dapat menghasilkan layanan</span> <br></br>
            <span>internet dengan kualitas jaringan yang memadai dan stabil serta dapat diandalkan.</span> <br></br> */}
            <p>
                
                Layanan tambahan atau pelengkap yang merupakan layanan IT Solution untuk meningkatkan kinerja baik infrastruktur di perusahaan pelanggan, 
yaitu dengan melakukan Analisa, merancang solusi serta mengatasi masalah dengan maintenance berkala oleh tenaga ahli yang berkompeten dan berpengalaman dibidang IT, secara tidak langsung layanan ini dapat mengurangi biaya investasi awal serta mengurangi kebutuhan extra SDM dalam memelihara infrastruktur dan meningkatkan fokus kinerja SDM perusahaan pelanggan.
            </p>
        </div>
        <div className='row justify-content-between '>
            <div className='col-md-3 mt-5'>
                <img src='/assets/img/gallery/wifi.png' alt='Tachyon fiber-optic' className='img-fluid' />
                <h3 className='text-dark mt-5 fw-bold  '>Wireless Fidelity (WiFi)</h3>
                <p>
                    Layanan pengadaan serta pemeliharaan jaringan wifi di pelanggan yang akan survey, analisa dan dirancang serta dipertimbangkan sesuai dengan kebutuhan dan implemtasi dilakukan oleh tenaga ahli yang berpengalaman, sehingga dapat dipastikan jaringan wifi di pelanggan dapat berjalan dengan baik dan optimal. Layanan ini bersifat customize sesuai dengan kebutuhan sisi pelanggan.

                </p>
            
            </div>
            <div className='col-md-1 text-center d-flex justify-content-center d-none d-md-flex'>
                <div className='line' >
                </div>
            </div>
            <div className='col-md-3 mt-5'>
                <img src='/assets/img/gallery/cctv.png' alt='Tachyon wireless' className='img-fluid' />
                <h3 className='text-dark mt-5 fw-bold '>Closed Circuit Television (CCTV)</h3>
                <p>
                    Layanan pengadaan serta pemeliharaan camera keamanan di pelanggan yang akan survey, analisa dan dirancang serta dipertimbangkan sesuai dengan kebutuhan dan implemtasi dilakukan oleh tenaga ahli yang berpengalaman, sehingga dapat dipastikan jaringan wifi di pelanggan dapat berjalan dengan baik dan optimal. Layanan ini bersifat customize sesuai dengan kebutuhan sisi pelanggan.
                </p>
            </div>

                <div className='col-md-1 text-center d-flex justify-content-center d-none d-md-flex'>
                    <div className='line' >
                    </div>
                </div>

            <div className='col-md-3 mt-5'>
                <img src='/assets/img/gallery/tech.png' alt='Tachyon wireless' className='img-fluid' />
                <h3 className='text-dark mt-5 fw-bold '>Networking</h3>
                <p>
                    Layanan pengadaan, pembangunan serta pemeliharaan infrastruktur/jaringan Gedung maupun antar gedung di pelanggan yang akan survey, analisa dan dirancang serta dipertimbangkan sesuai dengan kebutuhan dan implementasi dilakukan oleh tenaga ahli yang berpengalaman, sehingga dapat dipastikan jaringan wifi di pelanggan dapat berjalan dengan baik dan optimal. Layanan ini bersifat customize sesuai dengan kebutuhan sisi pelanggan.
                </p>
            </div>
        </div>
    </div>
  )
}
