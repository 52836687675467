import React from 'react'
import { Jumbotron } from './Jumbotron'
import NavBar3 from '../../components/NavBar3'
import { HeroSection } from './HeroSection'
import { TeamIllustration } from './TeamIllustration'
import Footer  from '../welcome/Footer'

export const Team = () => {
  return (
    <div>
        <NavBar3 />
        <Jumbotron />
        <HeroSection />
        <TeamIllustration />
        <Footer />
    </div>
  )
}
