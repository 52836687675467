import React, { useEffect, useState } from 'react'
import { Navbar } from '../../components/admin/Navbar'
import { Sidebar } from '../../components/admin/Sidebar'
import { AuthSession_objectGetData } from '../../helper/session/AuthSession'


export const Dashboard = () => {
  const [partnerData, set_partnerData ] = useState('')

  useEffect( () => {
    retriveSession();
  },[])

  const retriveSession = async () => {
      const pd = await AuthSession_objectGetData('partnerData');
      if ( pd ) {
          // console.log('pd', pd[0])
          set_partnerData(pd[0]);
      }
  }

  
  return (
    <div className='container-fluid'>
      <div className='row'>
          <Sidebar />
          <div className='col-lg-10 col-sm-12'>
            <Navbar />
            <div className=''>
              {/* CONTENT HERE */}
              <p>Hello {partnerData.name} </p>
            </div>
          </div>
      </div>
    </div>
  )
}
