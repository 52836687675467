import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Blog } from './pages/blog/Blog'
import { BlogView } from './pages/blog-view/BlogView'
import { Speedtest } from './pages/speedtest/Speedtest'
import ScrollToTop from './components/ScrollToTop'
import { Login } from './pages/login/Login'
import { Dashboard } from './pages/auth/Dashboard'
import { AuthMiddleware } from './Middlewares/AuthMiddleware'
import { GuestMiddleware } from './Middlewares/GuestMiddleware'
import FaqPage from './pages/faq/Faq'
import { TicketPage } from './pages/auth/tickets/TicketsPage'
import Welcome from './pages2/welcome/Welcome'
import { InternetDedicated } from './pages2/internet-dedicated/InternetDedicated'
import { InternetBroadband } from './pages2/internet-broadband/InternetBroadband'
import { ManageService } from './pages2/manage-service/ManageService'
import Test from './pages2/Test'
import MePage from './pages2/me/MePage'
import { Bantuan } from './pages2/bantuan/Bantuan'
import { Team } from './pages2/team/Team'
import { Info } from './pages2/info/Info'

export const MyRoutes2 = () => {
  return (
    <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route 
                path='/'
                element={
                    <Welcome />
                }
            />
            <Route 
                path='/test'
                element={
                    <Test />
                }
            />

            <Route 
                path='/about'
                element={
                    <MePage />
                }
            />

            <Route 
                path='/login'
                element={
                    <GuestMiddleware>
                        <Login />
                    </GuestMiddleware>
                }
            />

             <Route 
                path='/internet-dedicated'
                element={
                    <InternetDedicated />
                }
            />

            <Route 
                path='/internet-broadband'
                element={
                    <InternetBroadband />
                }
            />

            <Route 
                path='/managed-service'
                element={
                    <ManageService />
                }
            />

            <Route 
                path='/blog'
                element={
                    <Blog />
                }
            />
            <Route 
                path='/blog-view'
                element={
                    <BlogView />
                }
            />

            <Route 
                path='/speedtest'
                element={
                    <Speedtest />
                }
            />

            <Route 
                path='/faq'
                element={
                    <FaqPage />
                }
            />

            <Route 
                path='/bantuan'
                element={
                    <Bantuan />
                }
            />

            <Route 
                path='/team'
                element={
                    <Team />
                }
            />

            <Route 
                path='/info'
                element={
                    <Info />
                }
            />


            {/* AUTH ROUTES */}

            <Route 
                path='/dashboard'
                element={
                    <AuthMiddleware>
                        <Dashboard />
                    </AuthMiddleware>
                }
            />

            <Route 
                path='/tickets'
                element={
                    <AuthMiddleware>
                        <TicketPage />
                    </AuthMiddleware>
                }
            />
        </Routes>
    </BrowserRouter>
  )
}
