import React from 'react'
import { useNavigate } from 'react-router-dom'

export const CardBlog = ({ item, title, desc}) => {
    const nav = useNavigate();

  return (
    <div className="col-lg-3 col-md-6" onClick={ () => nav('/blog-view')}>
        <div className="card-blog" data-aos="zoom-in" data-aos-delay={100}>
            <div className="pic"><img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" /></div>
            <div className="card-blog-info text-start px-2">
                <h4>{title}</h4>
                <span>{desc}</span>
                <div className="social d-flex justify-content-between align-items-center">
                    <small style={{ fontSize: 10}}>12 April 2023</small>
                    {/* <small style={{ fontSize: 10}}>12 April 2023</small> */}
                </div>
            </div>
        </div>
    </div>
  )
}
