import React from 'react'

import './page.css'

export const FaqSection = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div id="faq-section" className=' text-white col-md-12'>
          <div className='flex-column'>
            <h3 className='fw-bold'>
                FAQ
              </h3>
              <h6>Beberapa pertanyaan yang mungkin bisa terjawab</h6>
          </div>
        </div>
      </div>
      <div className='row bg-primary' >
        <div className='col-12'>
          <div className="d-flex align-items-start">
            <div className='col-md-2 bg-primary' style={{ height: 'auto', marginLeft: -13}}>
              <div className="nav flex-column nav-pills"  id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <div className="tabz nav-link active" id="v-pills-Administrasi-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Administrasi" type="button" role="tab" aria-controls="v-pills-Administrasi" aria-selected="true">Administrasi</div>
                <div className="tabz nav-link" id="v-pills-Troubleshoot-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Troubleshoot" type="button" role="tab" aria-controls="v-pills-Troubleshoot" aria-selected="false">Troubleshoot</div>
                <div className="tabz nav-link" id="v-pills-SpeedTest-tab" data-bs-toggle="pill" data-bs-target="#v-pills-SpeedTest" type="button" role="tab" aria-controls="v-pills-SpeedTest" aria-selected="false">SpeedTest</div>
              </div>
            </div>
            <div className='col-md-10'>
              <div className="tab-content bg-white" id="v-pills-tabContent">
                <div className="tab-pane fade show active" id="v-pills-Administrasi" role="tabpanel" aria-labelledby="v-pills-Administrasi-tab">
                  <section id="faqx" className="faq section-bg">
                    <div className="container">
                        <div className="faq-list">
                          <ul>
                              <li data-aos="fade-up">
                              <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1"> Pembayaran melalui ATM BCA <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                              <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                                  <p>
                                    ...
                                  </p>
                              </div>
                              </li>
                              <li data-aos="fade-up" data-aos-delay={100}>
                                  <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">Pembayaran dengan M-Banking<i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                                  <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                                      <p>
                                        ...
                                      </p>
                                  </div>
                              </li>
                              <li data-aos="fade-up" data-aos-delay={200}>
                              <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">Pembayaran dengan Internet Banking<i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                              <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                                  <p>
                                    ...
                                  </p>
                              </div>
                              </li>
                              <li data-aos="fade-up" data-aos-delay={300}>
                              <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">Pembayaran dengan ATM BANK lain<i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                              <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                                  <p>
                                    ...
                                  </p>
                              </div>
                              </li>
                          </ul>
                        </div>
                    </div>
                  </section>
                </div>
                <div className="tab-pane fade" id="v-pills-Troubleshoot" role="tabpanel" aria-labelledby="v-pills-Troubleshoot-tab">...</div>
                <div className="tab-pane fade" id="v-pills-SpeedTest" role="tabpanel" aria-labelledby="v-pills-SpeedTest-tab">...</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )

  return (
    <div>
      <div id='faq-section' className='text-white'>
        <div className='flex-column container-fluid'>
          <h3 className='fw-bold'>
            FAQ
          </h3>
          <h6>Beberapa pertanyaan yang mungkin bisa terjawab</h6>
        </div>
      </div>
      <div className=''>
        <div className='d-flex'>
          <div className='col-md-4'>
            <div className='px-4'>
              <div className='container-tab-faq-section'>
                <span className='h4'>Administrasi</span>
              </div>
              <div className='container-tab-faq-section'>
                <span className='h4'>Troubleshoot</span>
              </div>
              <div className='container-tab-faq-section'>
                <span className='h4'>SpeedTest</span>
              </div>
            </div>
          </div>
          <div className='col-md-8'>

          </div>
        </div>
      </div>
    </div>
  )
}
