import React from 'react'

export const Hero = () => {
  return (
    <div className='bg-white pt-5 mt-5 container col-md-7'>
      <div className='mb-3' style={{ display: 'flex', alignItems: 'center'}}>
        <img  
            src='https://res.cloudinary.com/practicaldev/image/fetch/s--soctykON--/c_fill,f_auto,fl_progressive,h_50,q_auto,w_50/https://dev-to-uploads.s3.amazonaws.com/uploads/user/profile_image/86895/694d86fe-62a2-40ee-8f86-0013f9018642.jpg'
            width={40}  
            height={40}
            style={{
              borderRadius: 60,
              marginRight: 8, 
            }}  
        />
        <div>
          <span style={{fontWeight: '700'}}>Sathish Kumar N</span> <br />
          <span style={{ fontSize: 12}}>Posted on Jun 18 • Updated on Jun 22</span>
        </div>
      </div>

      {/* TITLE */}
      <div className='mb-5'>
            <h3 className='fw-bold'>Things you might not know about Next Image</h3>
      </div>

      <div className='text-center mb-5'>
        <img  src='https://media.kompas.tv/library/image/content_article/article_img/20210605235310.jpg' className="img-fluid"/>
      </div>

      <div>
        <p>
          If you've worked with Next.js, it's likely that you've come across Next Image component. This hassle-free image optimization solution not only provides support for modern formats such as webp and avif but also generates multiple versions tailored to different screen sizes.
        </p>
        <p>
        However, as is the case with any magic, there's a solid foundation of hard work that enables it to function seamlessly. In this article, we're going to explore how Next Image works and clear up some common misconceptions surrounding it.
        </p>
      </div>
    </div>
  )
}
