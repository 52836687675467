import React from 'react'

export default function WeAreReady() {
  
  return (
    <div id="we-are-ready" className='px-4' >
        {/* <img src='/assets/img/gallery/we-are-ready.jpg'  className='img-fluid'/> */}
        {/* <img src='/assets/img/gallery/we-are-ready.jpg'    className='img-fluid'/> */}
        <div className='row align-items-center justify-content-center mb-3'>
          <div className='col-md-3 mb-5' data-aos='fade' >
              <span className='text-white wearereadyforyou h1'>WE ARE READY <br></br> FOR YOU</span>
              <br></br>
              <span className='text-white wearereadyforyou2'>Beralih sekarang dan rasakan perbedaanya</span>
          </div>
          <div className='col-md-4 mb-5' data-aos='fade'>
            <div className='card shadow3' 
              style={{
                borderTopRightRadius: 150,
                borderBottomLeftRadius: 150,
                overflow: 'hidden'
              }}
            >
              <div className='w-100 py-2' style={{ position: 'absolute', bottom: 0, background: 'rgba(0,0,0,0.5)'}}>
                <div className='text-end me-3'>
                  <span className='text-white fs-6 fw-bold'>Tenaga Profesional <br></br> dalam melayani</span>
                </div>
              </div>
              <img 
                src='/assets/img/gallery/gallery-3.jpg'  
                className='img-fluid'
                style={{
                  height: 200,
                }}
              />
            </div>
          </div>
        </div>
        <div className='row align-items-center justify-content-center'>
          <div className='col-md-4 mb-5' data-aos='fade'>
            <div className='card shadow3' 
              style={{
                borderTopRightRadius: 150,
                borderBottomLeftRadius: 150,
                overflow: 'hidden'
              }}
            >
              <div className='w-100 py-2' style={{ position: 'absolute', bottom: 0, background: 'rgba(0,0,0,0.5)'}}>
                <div className='text-end me-3'>
                  <span className='text-white fs-6 fw-bold'>Kapasitas bandwidth <br></br> yang besar</span>
                </div>
              </div>
              <img 
                src='/assets/img/gallery/gallery-3.jpg'  
                className='img-fluid'
                style={{
                  height: 200,
                }}
              />
            </div>
          </div>
          <div className='col-md-4 mb-5' data-aos='fade'>
            <div className='card shadow3' 
              style={{
                borderTopRightRadius: 150,
                borderBottomLeftRadius: 150,
                overflow: 'hidden'
              }}
            >
              <div className='w-100 py-2' style={{ position: 'absolute', bottom: 0, background: 'rgba(0,0,0,0.5)'}}>
                <div className='text-end me-3'>
                  <span className='text-white fs-6 fw-bold'>Koneksi internet <br></br> yang cepat dan stabil</span>
                </div>
              </div>
              <img 
                src='/assets/img/gallery/gallery-3.jpg'  
                className='img-fluid'
                style={{
                  height: 200,
                }}
              />
            </div>
          </div>
        </div>
    </div>
  )
}
