import React from 'react'
import Header from '../welcome/Header'
import { Footer } from '../welcome/Footer'
import { Hero } from './Hero'
import { AnotherPost } from './AnotherPost'

export const BlogView = () => {
  return (
    <>
        <Header />
        <Hero />
        <AnotherPost />
        <Footer />
    </>
  )
}
