import axios from "axios";
// require('dotenv').config()
export const ApiLogin = async (params) => {
    try {
        const res = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/portal/my_login',
            data: {
              username: params.username,
              password: params.password
            }
        });

        return res;
    } catch (error) {
        console.log('ApiLogin Error', error)
        return error.response;
        
    }
}