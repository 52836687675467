import React from 'react'

export const About = () => {
  return (
    <>

        {/* ======= About Section ======= */}
        <section id="about" className="about">
        <div className="container-fluid">
            <div className="row">
            <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                {/* <a href="https://www.youtube.com/watch?v=StpBR2W8G5A" className="glightbox play-btn mb-4" /> */}
            </div>
            <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
                <h3>PT Remala Abadi</h3>
                <p>
                Adalah salah satu Internet Service Provider yang mana telah berdiri sejak tahun 2007 dibawah manajemen PT. Remala Abadi. Kami melayanai kebutuhan Akses Internet, Network & IT Solution yang menjadikan sebuah layanan Managed Services yang didukung dengan sumber daya manusia yang profesional dan dukungan infrastruktur jaringan yang handal yang berupa jaringan Fiber Optic maupun Wireless yang menggunakan teknologi terbaru.
                </p>
                <p>
                Dengan selalu berfikir kreatif dan inovatif serta yakin dan optimis untuk dapat memberikan layanan serta solusi yang tepat, handal dan tentunya efektif dan efisien.
                </p>
            </div>
            </div>
        </div>
        </section>
        {/* End About Section */}

    </>
  )
}
