import React from 'react'

import './Info.css'
import NavBar3 from '../../components/NavBar3'
import Footer from '../welcome/Footer'

export const Info = () => {
  return (
    <div>
        <NavBar3 />
        <div>
            <nav style={{ background: '#135EA6', paddingTop: 100, paddingBottom: 25}}>
                <div className="nav nav-pills justify-content-center" id="nav-tab" role="tablist">
                    <button className="nav-link  nav-link-info text-white fw-bold active" id="nav-activity-tab" data-bs-toggle="tab" data-bs-target="#nav-activity" type="button" role="tab" aria-controls="nav-activity" aria-selected="true">Activity</button>
                    <button className="nav-link nav-link-info text-white fw-bold" id="nav-article-tab" data-bs-toggle="tab" data-bs-target="#nav-article" type="button" role="tab" aria-controls="nav-article" aria-selected="false">Article</button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-activity" role="tabpanel" aria-labelledby="nav-activity-tab" tabIndex={0}>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-md-8'>
                                <div className='row justify-content-center py-5'>
                                    <div className='col-md-4 mb-5'>
                                        <div style={{ position: 'relative'}}>
                                            <img src='/assets/img/info1.png' className='img-fluid'/>
                                            <div style={{ 
                                                background: 'rgba(240,240,240,0.6)',
                                                position: 'absolute',
                                                bottom: 0,
                                                color: 'white',
                                                width: '100%',
                                                height: 75,
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}>
                                                <p className='text-dark'>
                                                    Internet Tercepat di dunia
                                                </p>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <p className='tgl mt-2'>
                                                06 March 2023
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-5'>
                                        <div style={{ position: 'relative'}}>
                                            <img src='/assets/img/info2.png' className='img-fluid'/>
                                            <div style={{ 
                                                background: 'rgba(240,240,240,0.6)',
                                                position: 'absolute',
                                                bottom: 0,
                                                color: 'white',
                                                width: '100%',
                                                height: 75,
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}>
                                                <p className='text-dark'>
                                                    5 Kegiatan Seru Lebaran
                                                </p>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <p className='tgl mt-2'>
                                                06 March 2023
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-5'>
                                        <div style={{ position: 'relative'}}>
                                            <img src='/assets/img/info3.png' className='img-fluid'/>
                                            <div style={{ 
                                                background: 'rgba(240,240,240,0.6)',
                                                position: 'absolute',
                                                bottom: 0,
                                                color: 'white',
                                                width: '100%',
                                                height: 75,
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}>
                                                <p className='text-dark'>
                                                Tips makan sehat sehari-hari
                                                </p>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <p className='tgl mt-2'>
                                                06 March 2023
                                            </p>
                                        </div>
                                    </div>

                                    <div className='col-md-4 mb-5'>
                                        <div style={{ position: 'relative'}}>
                                            <img src='/assets/img/info1.png' className='img-fluid'/>
                                            <div style={{ 
                                                background: 'rgba(240,240,240,0.6)',
                                                position: 'absolute',
                                                bottom: 0,
                                                color: 'white',
                                                width: '100%',
                                                height: 75,
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}>
                                                <p className='text-dark'>
                                                    Internet Tercepat di dunia
                                                </p>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <p className='tgl mt-2'>
                                                06 March 2023
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-5'>
                                        <div style={{ position: 'relative'}}>
                                            <img src='/assets/img/info2.png' className='img-fluid'/>
                                            <div style={{ 
                                                background: 'rgba(240,240,240,0.6)',
                                                position: 'absolute',
                                                bottom: 0,
                                                color: 'white',
                                                width: '100%',
                                                height: 75,
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}>
                                                <p className='text-dark'>
                                                    5 Kegiatan Seru Lebaran
                                                </p>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <p className='tgl mt-2'>
                                                06 March 2023
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-5'>
                                        <div style={{ position: 'relative'}}>
                                            <img src='/assets/img/info3.png' className='img-fluid'/>
                                            <div style={{ 
                                                background: 'rgba(240,240,240,0.6)',
                                                position: 'absolute',
                                                bottom: 0,
                                                color: 'white',
                                                width: '100%',
                                                height: 75,
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}>
                                                <p className='text-dark'>
                                                Tips makan sehat sehari-hari
                                                </p>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <p className='tgl mt-2'>
                                                06 March 2023
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-article" role="tabpanel" aria-labelledby="nav-article-tab" tabIndex={0}>...</div>
            </div>
        </div>
        <Footer />

    </div>
  )
}
