import React from 'react'

export const Counter = () => {
  return (
    <div className='container text-white text-center mb-5'>
        <div className='row'>
            <div className='col'>
                <div>
                    <span data-purecounter-start={0} data-purecounter-end={16} data-purecounter-duration={1} className="purecounter h1 fw-bold" /> <span className='h1 fw-bold'>+</span>
                    <h4 className='h4 fw-bold' style={{ marginTop: -10}}>YEAR</h4>
                </div>
            </div>
            <div className='col'>
                <div>
                    {/* <h3 className='h1 fw-bold'>3613+</h3> */}
                    <span data-purecounter-start={0} data-purecounter-end={3613} data-purecounter-duration={1} className="purecounter h1 fw-bold" /> <span className='h1 fw-bold'>+</span>

                    <h4 className='h4 fw-bold' style={{ marginTop: -10}}>CLIENT</h4>
                </div>
            </div>
            <div className='col'>
                <div>
                    <span data-purecounter-start={0} data-purecounter-end={240} data-purecounter-duration={1} className="purecounter h1 fw-bold" /> <span className='h1 fw-bold'>+</span>
                    <h4 className='h4 fw-bold' style={{ marginTop: -10}}>EMPLOYEED</h4>
                </div>
            </div>
        </div>
    </div>
  )
}
