import React from 'react'
import { CardBlog } from '../blog/CardBlog'

export const AnotherPost = () => {
  return (
    <div>
        {/* ======= Team Section ======= */}
        <section id="blog" className="blog">
            <div className="container">
                <div className="section-title mt-5" data-aos="fade-up">
                    <p>Post lainnya</p>
                </div>
                <div className="row" data-aos="fade-left">
                    <CardBlog title={'Aldi taher mencalonkan diri'} desc='Aldi taher mencalonkan diri...'/>
                    <CardBlog title={'Aldi taher mencalonkan diri'} desc='Aldi taher mencalonkan diri...'/>
                    <CardBlog title={'Aldi taher mencalonkan diri'} desc='Aldi taher mencalonkan diri...'/>
                </div>
            </div>
        </section>{/* End Team Section */}

    </div>
  )
}
