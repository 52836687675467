import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthSession_objectGetData } from '../../helper/session/AuthSession';
import { useAuth } from '../../contexts/auth';
import { SidebarItem } from './SidebarItem';
import useWindowSize from '../../hooks/useWindowSize';
import { MyDropdownLI } from '../utils/MyDropdown';


export const Navbar = () => {
    const { signOut } = useAuth();
    const { width } = useWindowSize();

    const nav = useNavigate();
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const [name, set_name] = useState('');
    const [partnerData, set_partnerData ] = useState('')

    useEffect( () => {
        retriveSession();

    }, [])

    const handleMobileNavToggle = () => {
      setIsMobileNavOpen(!isMobileNavOpen);
    };

    const handleLogout = () => {
        signOut();
        // nav('/login');
        window.location.href = '/login';
    };

    const retriveSession = async () => {
        const pd = await AuthSession_objectGetData('partnerData');
        if ( pd ) {
            // console.log('pd', pd[0])
            set_partnerData(pd[0]);
        }
    }
  
  return (
    <>
        <header id="header" className="container-fluid d-flex align-items-center header-transparentx bg-light">
            <div className=" d-flex align-items-center justify-content-between" style={{ width: '100%',}}>
                <div className="logo">
                    {
                        width < 991 &&
                        <Link to="/"> <img src="assets/img/my-logo.png" alt="" class="img-fluid" /></Link>
                    }
                </div>
                <nav id="navbar" className={`navbar ${isMobileNavOpen && width < 991 ? 'navbar-mobile' : ''} `}>
                    <ul>
                        {
                            width < 991 &&
                            <SidebarItem />
                        }
                        
                        <MyDropdownLI title={ 
                            <>
                                <div className='rounded-cirlce me-2' style={{ width: 40, height: 40,} }>
                                    <img src={`data:image/png;base64, `+partnerData.avatar_128} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 100}} />
                                </div>
                                <div>
                                    {partnerData.name}
                                </div>
                            </>
                        }>
                            <li>
                                <Link to="/speedtest" className='d-block'>
                                    <div>
                                        {partnerData.name}
                                    </div>
                                    <div style={{ fontSize: 12, color: 'gray'}}>{partnerData.email}</div>
                                </Link>
                            </li>
                            <li onClick={ handleLogout }><Link href="#" >Logout</Link></li>
                        </MyDropdownLI>
                    </ul>
                <i className={`mobile-nav-toggle bi ${isMobileNavOpen ? 'bi-x text-light' : 'bi-list'}`} 
                  onClick={handleMobileNavToggle}
                />
                </nav>{/* .navbar */}
            </div>
      </header>{/* End Header */}
 
    </>
  )
}
