import React from 'react'

import './Team.css'

export const HeroSection = () => {
  return (
    <section className='container-fluid' style={{ background: '#EBEBEB' }}>
        <div className='row justify-content-center'>
            <div className='col-md-2 text-center mb-3'>
                <div className='card-team'>
                    <img src='/assets/img/integrity.png'  alt='integrity' style={{ width: 75, height: 75}} className='img-fluid mb-3'/>
                    <h4 className='text-primary fw-bold'>Integrity</h4>
                    <small>Bekerja dengan intergritas yang baik</small>
                </div>
            </div>

            <div className='col-md-2 text-center mb-3'>
                <div className='card-team'>
                    <img src='/assets/img/result.png'  alt='result' style={{ width: 75, height: 75}} className='img-fluid mb-3'/>
                    <h4 className='text-primary fw-bold'>Result</h4>
                    <small>Kepuasan pelanggan adalah target utama</small>
                </div>
            </div>

            <div className='col-md-2 text-center mb-3'>
                <div className='card-team'>
                    <img src='/assets/img/quality.png'  alt='quality' style={{ width: 75, height: 75}} className='img-fluid mb-3'/>
                    <h4 className='text-primary fw-bold'>Quality</h4>
                    <small>Memiliki pengalaman yang profesional</small>
                </div>
            </div>

            <div className='col-md-2 text-center mb-3'>
                <div className='card-team'>
                    <img src='/assets/img/solution.png'  alt='solution' style={{ width: 75, height: 75}} className='img-fluid mb-3'/>
                    <h4 className='text-primary fw-bold'>Solution</h4>
                    <small>Memberikan solusi yang diperlukan</small>
                </div>
            </div>
            
        </div>
    </section>
  )
}
