import React from 'react'

import './Content.css'

export const Content = () => {
  return (
    <div className='container'>
        <div className='header'>
            <h5 className='text-dark fw-bold'>Apa itu ? Internet Dedicated</h5>
            <span>Layanan internet dedicated untuk perusahaan/corporate</span> <br></br>
            <span>dalam memenuhi kebutuhan akses internet 24 jam ke internet global</span> <br></br>
            <span>dengan alokasi bandwidth dedicated (1:1) yang dapat menjamin kestabilan jaringan</span> <br></br>
            <span>dan dapat diandalkan setiap saat, dengan dukungan backbone jaringan Fiber Optic dan</span> <br></br>
            <span>Opsi penggunaan perangkat ke pelanggan yang luas, agar dapat memberikan</span> <br></br>
            <span>solusi yang tepat dan dapat diandalkan oleh pelanggan.</span> <br></br>
        </div>
        <div className='row justify-content-center '>
            <div className='col-md-5 mt-5'>
                <img src='/assets/img/gallery/fiber-optic.png' alt='Tachyon fiber-optic' className='img-fluid' />
                <h3 className='text-dark mt-5  '>Fiber Optic</h3>
                <p>Untuk menjamin kestabilan jaringan dan dapat diandalkan,dengan dukungan jaringan Fiber Optic berkapastias 10 Gbps, dengan layanan ini pengguna internet dikantor dapat lancar dalam beraktifitas baik browsing, emailing, download dan upload file dengan cepat dan stabil.</p>
                <p>
                    <ul>
                        <li>
                            Tersedia mulai up to 20 Mbps s/d 10 Gbps. <br></br>
                        </li>
                        <li>
                            Support Priority 24 jam x 7 hari.. <br></br>
                        </li>
                        <li>
                            IP Public /29 (5). <br></br>
                        </li>
                        <li>
                            Free VPS. <br></br>
                        </li>
                        <li>
                            Support Layanan Tambahan.<br></br>
                        </li>
                        <li>
                            SLA 99,5%. <br></br>
                        </li>
                    </ul>
                </p>
            </div>
            <div className='col-md-1 text-center d-flex justify-content-center d-none d-md-flex'>
                <div className='line' >
                </div>
            </div>
            <div className='col-md-5 mt-5'>
                <img src='/assets/img/gallery/wireless.png' alt='Tachyon wireless' className='img-fluid' />
                <h3 className='text-dark mt-5  '>Wireless</h3>
                <p>Untuk menjamin kestabilan jaringan dan dapat diandalkan, layanan ini untuk memenuhi kebutuhan pelanggan yang diluar jangkauan jaringan fiber optic, dukungan perangkat wireless dengan teknologi terbaru dan dukunganjaringan Fiber Optic sebagai backbone utama disisi BTS. Dengan layanan ini pengguna internet dikantor dapat lancar dalam beraktifitas baik browsing, emailing, download dan upload file dengan cepat dan stabil..</p>
                <p>
                    <ul>
                        <li>
                            Tersedia mulai 100 Mbps s/d 10 Gbps.
                        </li>
                        <li>
                            Support Priority 24 jam x 7 hari.
                        </li>
                        <li>
                            IP Public /29 (5). <br></br>
                        </li>
                        <li>
                            Free VPS. <br></br>
                        </li>
                        <li>
                            Support Layanan Tambahan.<br></br>
                        </li>
                        <li>
                            SLA 99,5%. <br></br>
                        </li>
                    </ul>
                </p>
            </div>
        </div>
    </div>
  )
}
