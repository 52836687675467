import React, { useEffect, useState } from 'react'
import { Layout } from '../Layout'
import { ApiTicket } from '../../../api/TicketApi'
import MyTable from '../../../components/MyTable';
import { ModalStaticBackdrop } from '../../../components/ModalStaticBackdrop';


export const TicketPage = () => {
    const [my_data, set_my_data] = useState([]);
    const [show_modal, set_show_modal] = useState(false);

        const my_header =[
            {
                name: 'Name',
                selector: row => row.name,
            },
            {
                name: 'Stage ID',
                selector: row => row.stage_id[1],
            },
          ]
        

    useEffect( () => {
        getListTicket();
    }, []);

    const getListTicket = async () => {
        const res = await ApiTicket();
        console.log('getListTicket', getListTicket);
        set_my_data(res.data.data);
    }

    const handleShow = (data) => {
        set_show_modal(data)
    }

  return (
    <Layout title={'Tickets'}>
        <div className='row'>
            <div className='col'>
                {/* Button trigger modal */}
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                    >
                        Create
                    </button>

            </div>
        </div>
        <MyTable 
            my_data={my_data}
            my_header={my_header}
        />

        <ModalStaticBackdrop
            handleShow={handleShow}
            modal_title={'Create Ticket'}
            isShow={show_modal}
        >   
            <div>
                <div className='form-group'>
                    <label>Description</label>
                    <textarea 
                        className='form-control'
                    />
                </div>
            </div>

            <div className='d-flex justify-content-end mt-4'>
                <div className='col-md-4 me-2'>
                    <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal">Close</button>
                </div>
                <div className='col-md-4'>
                    <button className='btn btn-primary btn-sm'>Save</button>
                </div>
            </div>
            
        </ModalStaticBackdrop>

    </Layout>
  )
}
