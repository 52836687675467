import React from 'react'

import DataTable from 'react-data-table-component';


const FilterComponent = ({ filterText, onFilter, onClear }) => (
  	<div className='row'>
      <div className='col'>
        <input 
          id="search"
          type="text"
          placeholder="Filter By Name"
          aria-label="Search Input"
          value={filterText}
          onChange={onFilter}
          className='form-control'
        />
      </div>
      <div className='col-1 text-center'>
        <button type="button" className='btn btn-light' style={{ marginLeft: -20}} onClick={onClear}>
          X
        </button>
      </div>
  	</div>
  );
  


const MyTable = ({my_data, my_header}) => {
    const data = my_data;
    console.log('my_data', my_data);
    const header = my_header;


    const [filterText, setFilterText] = React.useState('');
  	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    var filteredItems = data.filter(
      item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    );

  

    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };

      return (
        <div className='row'>
        
          <div className='col'>
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
          </div>
         
        </div>
      );
    }, [filterText, resetPaginationToggle]);

  return <DataTable
            columns={header}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            // selectableRows
            pagination
            
          />
}

export default MyTable