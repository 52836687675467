import React from 'react'
import NavBar2 from '../../components/NavBar2'
import { Jumbotron } from './Jumbotron'
import { Content } from './Content'
import Footer from '../welcome/Footer'

export const InternetBroadband = () => {
  return (
    <div>
        <NavBar2 />
        <Jumbotron />
        <Content />
        <Footer />
    </div>
  )
}
