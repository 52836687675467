import React from 'react'

import './Team.css'
export const TeamIllustration = () => {
  return (
    <>
        <div className='container'>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-6 col-lg-3 col-sm-6 text-center mt-3' style={{ marginBottom: 100}}>
                    <div className='row justify-content-center'>
                        <div className='col-8'>
                            <div style={{ position: 'relative'}}>
                                <img src='/assets/img/teams/christian.png' alt='illustration' width={75}  className='illus'/>
                                <div className='card-illutration'>
                                    <h6>Christian</h6>
                                    <h6 className='small'>0821-1458-8988</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-3 col-sm-6 text-center mt-3' style={{ marginBottom: 100}}>
                    <div className='row justify-content-center'>
                        <div className='col-8'>
                            <div style={{ position: 'relative'}}>
                                <img src='/assets/img/teams/haris.png' alt='illustration' width={75}  className='illus'/>
                                <div className='card-illutration'>
                                    <h6>Haris Efendi</h6>
                                    <h6 className='small'>0878-8246-0181</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-3 col-sm-6 text-center mt-3' style={{ marginBottom: 100}}>
                    <div className='row justify-content-center'>
                        <div className='col-8'>
                            <div style={{ position: 'relative'}}>
                                <img src='/assets/img/teams/lucas.png' alt='illustration' width={75}  className='illus'/>
                                <div className='card-illutration'>
                                    <h6>Lukas</h6>
                                    <h6 className='small'>0817-6738-144</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row justify-content-center'>
                <div className='col-md-6 col-lg-3 col-sm-6 text-center mt-3' style={{ marginBottom: 100}}>
                    <div className='row justify-content-center'>
                        <div className='col-8'>
                            <div style={{ position: 'relative'}}>
                                <img src='/assets/img/teams/hery.png' alt='illustration' width={75}  className='illus'/>
                                <div className='card-illutration'>
                                    <h6>Hery Pramono</h6>
                                    <h6 className='small'>0857-1558-5355</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-3 col-sm-6 text-center mt-3' style={{ marginBottom: 100}}>
                    <div className='row justify-content-center'>
                        <div className='col-8'>
                            <div style={{ position: 'relative'}}>
                                <img src='/assets/img/teams/vivi.png' alt='illustration' width={75}  className='illus'/>
                                <div className='card-illutration'>
                                    <h6>Vivi Olivia</h6>
                                    <h6 className='small'>0812-8005-8682</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-3 col-sm-6 text-center mt-3' style={{ marginBottom: 100}}>
                    <div className='row justify-content-center'>
                        <div className='col-8'>
                            <div style={{ position: 'relative'}}>
                                <img src='/assets/img/teams/ganis.png' alt='illustration' width={75}  className='illus'/>
                                <div className='card-illutration'>
                                    <h6>Ganis</h6>
                                    <h6 className='small'>0821-1999-9824</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id='team-banner2'>

        </div>
    </>
  )
}
