import React from 'react'
import Iframe from 'react-iframe'
import Header from '../welcome/Header'
import { Footer } from '../welcome/Footer'
export const Speedtest = () => {
  return (
    <>
        <Header />
        <div className='text-center mt-5 pt-5' style={{ height: '100vh'}}>
            <Iframe
            src="https://sini.tachyon.net.id/"
            style="border:0px #ffffff none;"
            name="myiFrame"
            scrolling="no"
            frameborder="1"
            marginheight="0px"
            marginwidth="0px"
            height="100%"
            width="100%"
            allowfullscreen="allowfullscreen"></Iframe>
        </div>
        <Footer />
    </>
  )
}
