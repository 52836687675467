import React from 'react'
import useWindowDimensions from '../../contexts/useWindowDimensions';
import { MileStone } from '../../components/MileStone';
import { Header } from './Header';
import { Counter } from './Counter';
import { OurClient } from './OurClient';
import Footer from '../welcome/Footer';
import NavBar2 from '../../components/NavBar2';
import Navbar3 from '../../components/NavBar3';

export default function MePage() {
    const { width, height } = useWindowDimensions();

  return (
    <div className=''>
        {/* <NavBar2 /> */}
        <Navbar3 />
        <div className='milestone-bg pb-5'>
            <div
                style={{
                    height: ( width > 501 ? (width > 601 ? ( width > 701 ? (width > 801 ? '90vh' : '55vh' ) : '45vh') : '35vh') : '50vh')
                }}
            >
            </div>

            <Header />
        
            <MileStone />

            <Counter />

            <OurClient />


            
        </div>
            
        <Footer />
    </div>
  )
}
