import React, { useState } from 'react'
import { FaBeer, FaSearch, FaUserAlt } from 'react-icons/fa';

export default function NavBar2() {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const handleMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-xxl">
            {/* <a className="navbar-brand" href="#">Navbar</a> */}
            <a className="navbar-brand" href="/"><img src="assets/img/my-logo.png" alt="logo" class="img-fluid" width={'100'} /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse flex-column " id="navbarSupportedContent">
                <div className='d-flex w-100 align-items-center'>
                    <div className='me-auto'>
                        <ul className="navbar-nav navbar-nav-top me-auto mb-2 mb-lg-0 " >
                            <li className="nav-item">
                                <a className="nav-link nav-link-top active" aria-current="page" href="/about">About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-top" href="#">Remala Abadi</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-top" href="#">Info</a>
                            </li>
                            
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                            </li> */}
                        </ul>
                        <ul className="navbar-nav flex-row me-auto ">
                            <li className="nav-item">
                                <a className="nav-link nav-link-bot" href="#">Feature</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-bot" href="#">Hubungi Kami</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-bot" href="#">Bantuan</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className='d-flex justify-content-between ms-auto'>
                            <form className="d-flex   align-items-center" role="search">
                                <FaSearch size={25} className='ms-auto' style={{ color: '#000000'}} />
                                <input className="form-control me-2 w-50" style={{ border: 'none', color: '#000000' }} type="search" placeholder="Search" aria-label="Search" />
                            </form>
                            <button className='btn btn-primary me-2'> <span style={{ fontSize: 12}}>Check Coverage</span></button>
                            <div className='bg-secondary rounded-circle align-items-center d-flex justify-content-center' style={{ width: 40, height: 40}}>
                                    <FaUserAlt size={20} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
  )
}
