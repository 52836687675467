import React from 'react'

import './Content.css'

export const Content = () => {
  return (
    <div className='container'>
        <div className='header'>
            <h5 className='text-dark fw-bold'>Apa itu ? Internet Broadband</h5>
            <span>Layanan Internet dengan biaya lebih terjangkau</span> <br></br>
            <span>dengan dukungan fiber optic dan teknologi telekomunikasi terbaru yang bersifat</span> <br></br>
            <span>shared network dan mampu menyediakan jaringan internet cepat dengan bandwidth broadband/Upton</span> <br></br>
            <span>dengan pembagian serta perbandingan yang terukur sehingga dapat menghasilkan layanan</span> <br></br>
            <span>internet dengan kualitas jaringan yang memadai dan stabil serta dapat diandalkan.</span> <br></br>
        </div>
        <div className='row justify-content-center '>
            <div className='col-md-5 mt-5'>
                <img src='/assets/img/gallery/broadbad-bisnis.png' alt='Tachyon fiber-optic' className='img-fluid' />
                <h3 className='text-dark mt-5 fw-bold  '>Broadband Bisnis</h3>
                <p>
                    Layanan Internet dengan bandwidth broadband untuk memenuhi kebutuhan internet pelanggan bisnis/usaha kecil menengah (UKM) diberbagai bidang dalam mengelola dan meningkatkan kualitas bisnis serta kesuksesan bisnisnya. 
                    <br></br>
                    <br></br>
                    Layanan dengan biaya terjangkau dan dukungan jaringan fiber optic dan managemen bandwidth broadband yang terukur sesuai kebuthan bisnis, sehingga pelanggan dapat fokus dalam menjalankan bisnis/usahanya dengan tenang dan aman.</p>
                <p>
                    <ul>
                        <li>
                            Tersedia mulai up to 25 Mbps s/d 1 Gbps. <br></br>
                        </li>
                        <li>
                            Support 24 jam x 7 hari.<br></br>
                        </li>
                        <li>
                            IP Public /30 (1).
                            <br></br>
                        </li>
                        <li>
                            Support Layanan Tambahan.<br></br>
                        </li>
                       
                    </ul>
                </p>
            </div>
            <div className='col-md-1 text-center d-flex justify-content-center d-none d-md-flex'>
                <div className='line' >
                </div>
            </div>
            <div className='col-md-5 mt-5'>
                <img src='/assets/img/gallery/fiber-to-home.png' alt='Tachyon wireless' className='img-fluid' />
                <h3 className='text-dark mt-5 fw-bold '>Fiber to the Home</h3>
                <p>Layanan internet dengan bandwidth broadband untuk memenuhi kebutuhan akses internet rumahan/residential dan apartemen, sebagaimana meningkatnya kebutuhan akses internet rumahan serta mendukung aktivitas belajar online serta work from home (WFH) dengan baik.</p>

                <p>
                    <ul>
                        <li>
                            Tersedia mulai up to 25 Mbps s/d 100 Mbps.
                        </li>
                        <li>
                            Support Layanan Tambahan.<br></br>
                        </li>
                    </ul>
                </p>
            </div>
        </div>
    </div>
  )
}
