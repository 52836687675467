import React from 'react'
import useWindowDimensions from '../../contexts/useWindowDimensions'

const styles = {
    inputCheck: { 
        fontSize: 20, 
        fontWeight: '600', 
        opacity: 0.5, 
        border: 'none', 
        borderRadius: 0, 
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30
    }
}

const CarouselMobile = () => {
    const itemHeight = '50vh';
    return (
        <div>
            {/* <div style={{ background: 'red', height: '88vh', position: 'absolute', bottom: 25}}></div> */}
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
                </div>
                <div className="carousel-inner ">
                    <div className="carousel-item welcome active" style={{ height: itemHeight,}}>
                        <div className='d-block w-100 d-flex justify-content-center align-items-center' 
                            style={{ 
                                height: itemHeight, position: 'absolute', background: 'rgba(0,0,0,0.5)'
                            }}
                        >
                            <div className='w-50 text-center '>
                                <div >
                                    <span className='text-white   text-center fw-bold'>Menjadi pilihan terbaik untuk konektivitas anda</span>
                                </div>
                            </div>
                        </div>
                        <img src="/assets/img/gallery/banner1.jpg"  className="d-block w-100 img-fluid" alt="banner-welcome" 
                            style={{ 
                                height: itemHeight
                            }}
                        />
                    </div>
                    <div className="carousel-item welcome" style={{ height: itemHeight,}}>
                        <div className='d-block w-100 d-flex justify-content-center align-items-center' 
                            style={{ 
                                height: itemHeight, position: 'absolute', background: 'rgba(0,0,0,0.5)'
                            }}
                        >
                            <div className='w-50 text-center '>
                                <div >
                                    <span className='text-white text-center fw-bold '>Kami pilihan yang tepat untuk meningkatkan bisnis anda</span>
                                </div>
                            </div>
                        </div>
                        <img src="/assets/img/gallery/banner1.jpg"  className="d-block w-100 img-fluid" alt="banner-welcome" 
                            style={{ 
                                height: itemHeight
                            }}
                        
                        />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

        </div>
    )
}

const CarouselDesktop = () => {
    return (
        <div>
            {/* <div style={{ background: 'red', height: '88vh', position: 'absolute', bottom: 25}}></div> */}
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
                </div>
                <div className="carousel-inner ">
                    <div className="carousel-item welcome active" style={{ height: '88vh',}}>
                        <div className='d-block w-100 d-flex justify-content-center align-items-center' 
                            style={{ 
                                height: '88vh', position: 'absolute', background: 'rgba(0,0,0,0.5)'
                            }}
                        >
                            <div className='w-50 text-center '>
                                <div >
                                    <span className='text-white h1  text-center fw-bold'>Menjadi pilihan terbaik untuk konektivitas anda</span>
                                </div>
                                <div className='d-block d-flex mt-3'>
                                    <input className='form-control w-75' placeholder='Masukan alamat anda..' style={ styles.inputCheck } />
                                    <button className='btn btn-primary' style={{ borderTopRightRadius: 30, borderBottomRightRadius: 30}}>
                                        <span style={{ fontSize: 12}}> Check Availability </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <img src="/assets/img/gallery/banner1.jpg"  className="d-block w-100 img-fluid" alt="banner-welcome" />
                    </div>
                    <div className="carousel-item welcome" style={{ height: '88vh',}}>
                        <div className='d-block w-100 d-flex justify-content-center align-items-center' 
                            style={{ 
                                height: '88vh', position: 'absolute', background: 'rgba(0,0,0,0.5)'
                            }}
                        >
                            <div className='w-50 text-center '>
                                <div >
                                    <span className='text-white h1  text-center fw-bold '>Kami pilihan yang tepat untuk meningkatkan bisnis anda</span>
                                </div>
                                <div className='d-block d-flex mt-3'>
                                    <input className='form-control w-75' placeholder='Masukan alamat anda..' style={ styles.inputCheck } />
                                    <button className='btn btn-primary' style={{ borderTopRightRadius: 30, borderBottomRightRadius: 30}}>
                                        <span style={{ fontSize: 12}}> Check Availability </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <img src="/assets/img/gallery/banner1.jpg"  className="d-block w-100 img-fluid" alt="banner-welcome" />
                    </div>
                    {/* <div className="carousel-item welcome" style={{ height: '88vh'}}>
                        <div className='d-block w-100 d-flex justify-content-center align-items-center' style={{ height: '88vh', position: 'absolute', background: 'rgba(0,0,0,0.5)'}}>
                            <span className='text-white h1 w-50 text-center fw-bold'>Kami pilihan yang tepat untuk meningkatkan bisnis anda</span>
                        </div>
                        <img src="/assets/img/gallery/banner1.jpg"  className="d-block w-100 img-fluid" alt="banner-welcome" />
                    </div> */}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

        </div>
    )
}

export default function Carousel() {
    const { width, height } = useWindowDimensions();

    if ( width > 991 ) {
        return (
            <CarouselDesktop />
        )
    }

    if ( width < 992 ) {
        return (
            <CarouselMobile />
        )
    }


}
