import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { MyDropdownLI } from './utils/MyDropdown';
import useWindowDimensions from '../contexts/useWindowDimensions';
import { FaSearch, FaUserAlt } from 'react-icons/fa';
import { MyLink } from './utils/MyLink';

export default function NavBar3() {
    const nav = useNavigate();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const { width, height } = useWindowDimensions()

  const handleMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center header-transparentx bg-light">
            <div className="container-fluid d-flex align-items-center justify-content-between">
                <div className='align-items-center d-flex'>
                    <div className="logo">
                        {/* <h1><MyLink href="index.html"><span>ravena</span></MyLink></h1> */}
                        <MyLink to="/"> <img src="assets/img/my-logo.png" alt="" class="img-fluid" /></MyLink>
                    </div>
                    <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''} `}>
                        <div className='me-auto'>
                            <ul className='navbar-nav-topx' >
                                <li><MyLink className="nav-link nav-link-top active" to="/about">About Us</MyLink></li>
                                <li><MyLink className="nav-link nav-link-top" to="#">Remala Abadi</MyLink></li>
                                <li><MyLink className="nav-link nav-link-top" to="/info">Info</MyLink></li>
                            </ul>
                            <ul>
                                <li><MyLink className="nav-link nav-link-bot" to="#">Feature</MyLink></li>
                                <li><MyLink className="nav-link nav-link-bot" to="/team">Hubungi Kami</MyLink></li>
                                <li><MyLink className="nav-link nav-link-bot" to="/bantuan">Bantuan</MyLink></li>
                            </ul>
                            
                        </div>
                    </nav>{/* .navbar */}
                </div>
                <div>
                    <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''} `}>
                        {
                            // JIKA LEBAR KURANG DARI 992 AKTIFIN MOBILE NAV
                            width < 992 ?
                                <ul>
                                    <li><MyLink className="nav-link active" to="/about">About Us</MyLink></li>
                                    <li><MyLink className="nav-link" to="#">Remala Abadi</MyLink></li>
                                    <li><MyLink className="nav-link" to="/info">Info</MyLink></li>
                                    <li><MyLink className="nav-link" to="#">Feature</MyLink></li>
                                    <li><MyLink className="nav-link" to="/team">Hubungi Kami</MyLink></li>
                                    <li><MyLink className="nav-link" to="/bantuan">Bantuan</MyLink></li>
                                    <li>
                                        <form className="d-flex align-items-center p-2" role="search">
                                            <FaSearch size={25} className='ms-auto' style={{ color: '#000000'}} />
                                            <input className="form-control me-2" style={{ border: 'none', color: '#000000' }} type="search" placeholder="Search" aria-label="Search" />
                                        </form>
                                    </li>
                                    <li className='p-2'>
                                        <button className='btn btn-primary me-2 w-100'> <span style={{ fontSize: 12}}>Check Coverage</span></button>
                                    </li>
                                </ul>

                            :

                            <ul>
                                <li></li>
                                <form className="d-flex   align-items-center" role="search">
                                    <FaSearch size={25} className='ms-auto' style={{ color: '#000000'}} />
                                    <input className="form-control me-2 w-50" style={{ border: 'none', color: '#000000' }} type="search" placeholder="Search" aria-label="Search" />
                                </form>
                                <button className='btn btn-primary me-2'> <span style={{ fontSize: 12}}>Check Coverage</span></button>
                                <div className='bg-secondary rounded-circle align-items-center d-flex justify-content-center' style={{ width: 40, height: 40}}
                                    onClick={ () => {
                                        nav('/login')
                                    } }
                                >
                                    <FaUserAlt size={20} />
                                </div>
                            </ul>
                        }
                        
                        <i className={`mobile-nav-toggle bi ${isMobileNavOpen ? 'bi-x text-light' : 'bi-list'}`} 
                            onClick={handleMobileNavToggle}
                        />
                    </nav>{/* .navbar */}
                </div>
            </div>
      </header>{/* End Header */}
    </>
  )
}
