import React from 'react'
import './Sidebar.css'
import useWindowSize from '../../hooks/useWindowSize';
import { SidebarItem } from './SidebarItem';
export const Sidebar = () => {
    const { width } = useWindowSize();

  return (
    <div className={`bg-white shadow sidebar-container col-md-2 ${ width < 991 ? 'd-none' : ''}`}>
        <div className='sidebar-header'>
            <img src='/assets/img/my-logo.png' alt='logo' style={{ height: '100%', width: '100%', objectFit: 'cover'}} />
        </div>
        <div className='sidebar-content'>
           <nav className="navbar navbar-expand-lg">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-block">
                    <SidebarItem />
                    {/* <li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown
                        </span>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </li> */}
                    {/* <li className="nav-item">
                    <a className="nav-link disabled">Disabled</a>
                    </li> */}
                </ul>
            </nav>
        </div>
    </div>
  )
}
