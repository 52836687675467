import React, { useEffect, useState } from 'react'
import { ApiLogin } from '../../api/LoginApi';
import { AuthSession_objectStoreData, AuthSession_stringStoreData } from '../../helper/session/AuthSession';
import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
    var h = window.innerHeight;
    const nav = useNavigate('');
    const { signIn } = useAuth();
    const [loading, set_loading] = useState(false);
     
    const [_username, set_username] = useState('');
    const [_password, set_password] = useState('');

    useEffect( () => {
        console.log('height', h);
    }, [])


    const handleLogin = async () => {
        try {
            if ( !_username) {
                alert("Lengkapi username");
                return false;
            }
            if ( !_password) {
                alert("Lengkapi password");
                return false;
            }
            set_loading(true)
            const res = await ApiLogin({
                username: _username,
                password: _password,
            })
            set_loading(false)
            console.log('res res',res)

            //  if (res.response.status == 401) {
            //     alert('Invalid Username or Password')
            //     return false;
            // } 

        if ( res.data.code == 200 ) {
                AuthSession_stringStoreData('id', res.data.data[0].id)
                AuthSession_stringStoreData('token', res.data.token)
                AuthSession_stringStoreData('@name', res.data.data[0].name,'')
                AuthSession_stringStoreData('@mobile_phone', res.data.data[0].phone,'')
                AuthSession_stringStoreData('@login', res.data.data[0].login,'')
                AuthSession_stringStoreData('@passwordx', _password,'')
                AuthSession_stringStoreData('@employee_id', res.data.data[0].employee_id[0],'')
                AuthSession_stringStoreData('@employee_name', res.data.data[0].employee_id[1],'')
                AuthSession_stringStoreData('@company_id', res.data.data[0].company_id[0],'')
                AuthSession_stringStoreData('@company_name', res.data.data[0].company_id[1],'')
                AuthSession_stringStoreData('@profile_image', res.data.data[0].image_1920,'')
                AuthSession_objectStoreData('partnerData', res.data.partnerData)
                signIn();
                nav('/dashboard');
        } else {
                if (res.data.code == '401' ) {
                    alert('Invalid Username or Password')
                }
        }
            
        } catch (error) {
            console.log('errror',error)
        alert(error)
        }
    }
  return (
    <div className='bg-color' style={{ height: h, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div className='card col-md-4'>
            <div className='card-body'>
                <div class="slider-login">
                    {/* <div>your content 1</div>
                    <div>your content 2</div>
                    <div>your content 3</div> */}
                </div>
                <div className='mb-3'>
                    <h4>Welcome to Tachyon</h4>
                    <p className='login-caption'>
                        Please login to your account and start the adventure
                    </p>
                </div>
                <div className='form-group mb-3'>
                    <label className='mb-1'>Email</label>
                    <input 
                        type='email'
                        required
                        placeholder='Email address'
                        className='form-control'
                        value={_username}
                        onChange={ (e) => {
                            set_username(e.currentTarget.value);
                        }}
                    />
                </div>
                <div className='form-group mb-3'>
                    <label className='mb-1'>Password</label>
                    <input 
                        type='password'
                        required
                        placeholder='Password'
                        className='form-control'
                        value={_password}
                        onChange={ (e) => {
                            set_password(e.currentTarget.value);
                        }}
                    />
                </div>
                <div className='form-group mb-3'>
                    <button className='btn btn-primary w-100' onClick={ handleLogin }>Login</button>
                </div>
            </div>
        </div>
    </div>
  )
}
