import React from 'react'
import { primaryColor } from '../../helper/Colors'


export const Layanan = () => {
  return (
    <div className='container py-5'>
        <div className='row py-5 justify-content-center'>
            <div className='col-md-3 mb-3'>
                <div className='card' style={{ background: primaryColor, height: 135}}>
                    <div className='card-body text-center text-white'>
                        <img src='/assets/img/icons/icon-email.png'  alt='email' className='img-fluid icon-bantuan-layanan mb-3'
                        
                        />

                        <h4 className='fw-bold'>Email</h4>
                    </div>
                </div>
            </div>

            <div className='col-md-3 mb-3'>
                <div className='card' style={{ background: primaryColor, height: 135}}>
                    <div className='card-body text-center text-white'>
                        <img src='/assets/img/icons/icon-phone.png'  alt='call-center' className='img-fluid icon-bantuan-layanan mb-3'
                        
                        />

                        <h4 className='fw-bold'>Call Center</h4>
                    </div>
                </div>
            </div>

            <div className='col-md-3 mb-3'>
                <div className='card' style={{ background: primaryColor, height: 135}}>
                    <div className='card-body text-center text-white'>
                        <img src='/assets/img/icons/icon-paper.png'  alt='pengaduan pelayanan kami' className='img-fluid icon-bantuan-layanan mb-3'
                        
                        />

                        <h4 className='fw-bold'>Pengaduan pelayanan kami</h4>
                    </div>
                </div>
            </div>

           
        </div>
    </div>
  )
}
