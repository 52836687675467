import React from 'react'

const color = '#0716CD';

export const OurClient = () => {
  return (
    <div className='container text-white pt-3 pb-5 my-5 rounded' style={{ background: color}}>
        <div className='row justify-content-center py-3'>
            <div className='col-2'>
                <span className='h4 fw-bold'>Our Client</span>
            </div>
            <div className='col-2'>
                <span className='h4 fw-bold'>Our Partner</span>
            </div>
        </div>

        <div className='row pb-b'>
            <div className='col'>
                <div className='slider-our-client'>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/gramedia.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/google.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/ijo.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/fmi.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/cyber-indo.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/bsi.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/binus.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/apji.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/alfamart.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/akulaku.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/akamai.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/sumber-data-indonesia.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/nice.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/lazada-express.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                    <div className='mx-3 text-center justify-content-center d-flex'>
                        <img src='/assets/img/our-client/jasa-marga.png' alt='our-client' className='img-fluid rounded' />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
