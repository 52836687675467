import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { MyDropdownLI } from '../../components/utils/MyDropdown';

export default function Header() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const handleMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center header-transparentx bg-light">
            <div className="container d-flex align-items-center justify-content-between">
                <div className="logo">
                {/* <h1><Link href="index.html"><span>ravena</span></Link></h1> */}
                <Link to="/"> <img src="assets/img/my-logo.png" alt="" class="img-fluid" /></Link>
                </div>
                <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''} `}>
                <ul>
                    <li><Link className="nav-link scrollto active" to="/">About Us</Link></li>
                    <MyDropdownLI title={'Service'}>
                          <li><Link to="/internet-dedicated">Internet Dedicated</Link></li>
                          <li><Link to="/internet-broadband">Internet Broadband</Link></li>
                          <li><Link to="/managed-service">Managed Service</Link></li>
                    </MyDropdownLI>
                    <li><Link className="nav-link scrollto" href="#about">About</Link></li>
                    <li><Link className="nav-link scrollto" to="/blog">Blog</Link></li>
                    <li><Link className="nav-link scrollto" href="#team">Career</Link></li>
                    <MyDropdownLI title={'Support'}>
                        <li><Link to="/speedtest">Speedtest</Link></li>
                        <li><Link href="#">Contact</Link></li>
                        {/* <li className="dropdown"><Link href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></Link>
                          <ul>
                              <li><Link href="#">Deep Drop Down 1</Link></li>
                              <li><Link href="#">Deep Drop Down 2</Link></li>
                              <li><Link href="#">Deep Drop Down 3</Link></li>
                              <li><Link href="#">Deep Drop Down 4</Link></li>
                              <li><Link href="#">Deep Drop Down 5</Link></li>
                          </ul>
                        </li> */}
                        <li><Link href="/faq">FAQ</Link></li>
                    </MyDropdownLI>
                    <li><Link className="nav-link " to="/login">Login</Link></li>
                </ul>
                <i className={`mobile-nav-toggle bi ${isMobileNavOpen ? 'bi-x text-light' : 'bi-list'}`} 
                  onClick={handleMobileNavToggle}
                />
                </nav>{/* .navbar */}
            </div>
      </header>{/* End Header */}
    </>
  )
}
