import React, { useEffect } from 'react'
import { AuthSession_stringGetData } from '../helper/session/AuthSession';
import { useNavigate } from 'react-router-dom';

export const GuestMiddleware = ({children}) => {
  const nav = useNavigate();

    useEffect( () => {
      getSession();
    }, [])

    const getSession = async () => {

      const token = await AuthSession_stringGetData('token');
      if ( token ) {
        nav('/dashboard')
      }
    }
    
  return children
}
