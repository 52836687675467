import React from 'react'
import { MileStone } from '../components/MileStone'
import useWindowDimensions from '../contexts/useWindowDimensions';

export default function Test() {
    const { width, height } = useWindowDimensions();

  return (
    <div className='container-fluid milestone-bg'>
        <div
            style={{
                height: ( width > 501 ? (width > 601 ? ( width > 701 ? (width > 801 ? '85vh' : '55vh' ) : '45vh') : '35vh') : '25vh')
            }}
        >
            { width }
        </div>
    
        <MileStone />
    </div>
  )
}
