import milePic from './base milestone.png';
import milelinePic from './base milestone2.png';
import mileline2Pic from './base milestone3.png';
import item2007Pic from './2007.png';
import item2008Pic from './2008.png';
import item2009Pic from './2009.png';
import item2011Pic from './2011.png';
import item2023Pic from './2023.png';
import backgroundPic from './background.jpg';

export const mile = milePic;
export const mileline = milelinePic;
export const mileline2 = mileline2Pic;
export const item2007 = item2007Pic;
export const item2008 = item2008Pic;
export const item2009 = item2009Pic;
export const item2011 = item2011Pic;
export const item2023 = item2023Pic;
export const backgroundMile = backgroundPic;