import React from 'react'

import './Jumbotron.css'
export const Jumbotron = () => {
  return (
    <div id='jumbotron-bantuan' className='text-white'>
        <div className='flex-column text-center'>
            <h1 id='title' className='h1'>HELP & SUPPORT</h1>
            <h5>
                Kami siap melayani 24 jam
            </h5>
        </div>
    </div>
  )
}
