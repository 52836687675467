import React from 'react'
import { Link } from 'react-router-dom'

export const CardLayanan = ({ title, img_url, body, to}) => {
    return (
        <div className='col-md-3 mb-3'>
            <div className='text-center'>
                <div className='text-center row'>
                    <span className='fw-bold text-dark mb-2 '>{title}</span>
                </div>
                <view className='img-container '>
                    <img src={img_url} alt='internet dedicated' className='img-fluid' style={{height: 200, width: '100%'}} />
                </view>
                <view className='text-start p-3 card shadow' 
                    style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                        borderBottomRightRadius: 120,
                        borderBottomLeftRadius: 50,
                        marginTop: -20,
                        height: 230,
                    }}
                >
                    <span className='fw-nor
                    mal text-dark' style={{ fontSize: 14}} >
                       {body}
                    </span>
                    
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 20,
                        }}
                    >

                        <Link className='btn btn-sm btn-primary'
                            style={{
                                width: 100,
                                borderRadius: 50,
                            }}

                            to={to}
                        ><span style={{ fontSize: 12}}>Lebih lanjut</span></Link>
                    </div>
                </view>
            </div>
        </div>
    )
}

export default function LayananKami() {
  return (
    <>
        <section id="layanan-kami" className='container'>
            <div className='text-center mb-5'>
                <h3 className='fw-bold text-dark'>LAYANAN KAMI</h3>
                <h6 className='fw-bold text-dark'>Memaksimalkan Keunggulan konektivitas internet</h6>
            </div>

            <div className='row justify-content-between'>
                <CardLayanan 
                    to={'/internet-dedicated'}
                    title="Internet Dedicated"
                    img_url="/assets/img/gallery/internet-dedicated.jpg"
                    body={"Layanan internet dedicated untuk perusahaan/corporate dalam memenuhi kebutuhan akses internet 24 jam ke internet global dengan alokasi bandwidth dedicated (1:1)"}
                />

                <CardLayanan 
                    to={'/internet-broadband'}
                    title="Internet Broadband"
                    img_url="/assets/img/gallery/internet-broadband.jpg"
                    body={"Layanan internet dedicated untuk perusahaan/corporate dalam memenuhi kebutuhan akses internet 24 jam ke internet global dengan alokasi bandwidth dedicated (1:1)"}
                />

                <CardLayanan 
                    to={'/managed-service'}
                    title="Manage Service"
                    img_url="/assets/img/gallery/manage-service.jpg"
                    body={"Layanan internet dedicated untuk perusahaan/corporate dalam memenuhi kebutuhan akses internet 24 jam ke internet global dengan alokasi bandwidth dedicated (1:1)"}
                />
            </div>
        </section>
    </>
  )
}
