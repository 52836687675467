import React from 'react'

export const SidebarItem = () => {
  return (
    <>
        <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#">Quotations</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" href="#">Sales Orders</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" href="#">Invoices and Bills</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" href="#">Contracs</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" href="#">Projects</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" href="#">Task</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" href="/tickets">Tickets</a>
        </li>
    </>
  )
}
