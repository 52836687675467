import React from 'react'
import { Sidebar } from '../../components/admin/Sidebar'
import { Navbar } from '../../components/admin/Navbar'


export const Layout = ({ children, title }) => {
  return (
    <div className='container-fluid'>
      <div className='row'>
          <Sidebar />
          <div className='col-lg-10 col-sm-12'>
            <Navbar />
            <div className='my-3'>
                <h3>{title}</h3>
            </div>
            <div className=''>
              {/* CONTENT HERE */}
              {children}
            </div>
          </div>
      </div>
    </div>
  )
}
