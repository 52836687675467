import axios from "axios";
import { AuthSession_objectGetData, AuthSession_stringGetData } from "../helper/session/AuthSession";
// require('dotenv').config()
export const ApiTicket = async (params) => {
    const token = await AuthSession_objectGetData('token');
    const username = await AuthSession_stringGetData('@login');
    const password = await AuthSession_stringGetData('@passwordx');
    try {
        const res = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/portal/tickets',
            headers: {
                Authorization: 'Bearer '+token
            },
            data: {
              username: username,
              password: password
            }
        });

        return res;
    } catch (error) {
        console.log('ApiTicket Error', error)
        return error.response;
        
    }
}