import React from 'react'
import { Link } from 'react-router-dom'

export const OfficeLocation = () => {
  return (
    <>
        {/* ======= oFFICE LOCATION ======= */}
        <div className='container bg-white py-5'>
            <div className='row'>
                <div className='col'>
                    <h3 className='office-location-title fw-bold text-dark'>Office Location</h3>
                    <h5 className='office-location-sub-title text-dark fw-bold'>Find us here</h5>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='mb-3'>
                        <b className='text-dark'>Jakarta</b> 
                        <p>
                            Graha Mustika Ratu <br/>
                            Jl Gatot Subroto No.74-75 Lantai 10, Jakarta, <br/>
                            DKI Jakarta 12870.<br/>
                        </p>
                    </div>

                    <div className='mb-3'>
                        <b className='text-dark'>Bekasi</b> 
                        <p>
                            Jl Wibawa Mukti II No.3D RT 04/06, Jatiasih,  <br/>
                            Kota Bekasi, Jawa Barat 17423.  <br/>
                        </p>
                    </div>

                </div>
                <div className='col-md-6'>
                    <div className='mb-3'>
                        <b className='text-dark'>Petojo</b> 
                        <p>
                            Jl Petojo Viy I No.22 RT 02 RW 06, Cideng, Kecamatan <br/>
                            Gambir, Kota Jakarta Pusat, Daerah Khusus Ibukota <br/>
                            Jakarta 10150. <br/>
                        </p>
                    </div>

                    <div className='mb-3'>
                        <b className='text-dark'>Ujung Menteng</b> 
                        <p>
                            Ruko Inkopau Blok J3 Jl Raya Bekasi KM 25 RT 15 RW 01, <br/>
                            Ujung Menteng Kecamatan Cakung, Kota Jakarta Timur, <br/>
                            Daerah Khusus Ibukota Jakarta 13960. <br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
        {/* End oFFICE LOCATION */}

    </>
  )
}
