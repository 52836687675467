import React from 'react'
import Header from '../welcome/Header'
import { Footer } from '../welcome/Footer'
import { Faq } from '../welcome/Faq'
import { Hero } from './Hero'

export default function FaqPage() {
  return (
    <>
        <Header />
            <main id="main" className='container'>
                <Hero />
                <section id="faq" className="faq section-bg">
                    <div className="container">
                       
                        <div className="faq-list">
                        <ul>
                            <li data-aos="fade-up">
                            <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1"> Dimana saja jangkauan jaringan Tachyon Network Indonesia? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                            <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                                <p>
                                    Silahkan masuk ke menu “Coverage Area” atau klik link berikut ini 
                                    <a href='https://tachyon.net.id/coverage.html'>COVERAGE AREA</a>
                                </p>
                            </div>
                            </li>
                            <li data-aos="fade-up" data-aos-delay={100}>
                                <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">Bagaimana apabila lokasi saya tidak atau belum terjangkau? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                                <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                    Anda bisa masuk ke menu …., atau klik link berikut ini ….., dan masukan data lengkap beserta kontak anda atau perusahaan anda, kami akan melakukan survey atau pengecekan lokasi anda, atau anda dapat menghubungi kontak kami disini.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="fade-up" data-aos-delay={200}>
                            <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">Bagaimana cara berlangganan layanan? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                            <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                                <p>
                                Pendaftaran layanan Tachyon Network Indonesia dapat dilakukan dengan beberapa cara : <br />
                                
                                ▪ Daftar Online di website tachyon.net.id. <br />
                                ▪ Menghubungi Hotline di 021 xxxxxxxx. <br />
                                ▪ Melalui sales representative (NO WAKHUSUS). <br />
                                </p>
                            </div>
                            </li>
                            <li data-aos="fade-up" data-aos-delay={300}>
                            <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">Apakah ada biaya installasi atau pendaftaran untuk berlangganan? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                            <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                                <p>
                                Setiap pemasangan layanan Tachyon network Indonesia, pelanggan akan dikenakan biaya sesuai dengan kententuan setiap layanan atau promo yang berlaku. Detail nominal akan dijelaskan oleh sales representative kami.
                                </p>
                            </div>
                            </li>
                            <li data-aos="fade-up" data-aos-delay={400}>
                                <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">Kapan biaya installasi harus dibayarkan? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                                <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                    Biaya installasi harus dibayarkan diawal sebelum dilaksanakannya pemasangan.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="fade-up" data-aos-delay={500}>
                                <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">Bagaimana tahapan berlangganan sampai dengan layanan aktif? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                                <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                    ▪ Calon Pelanggan harus melakukan pendaftaran. <br />

                                    ▪ Dilakukan proses pengecekan jangkauan layanan atau dilaksanakan survey. <br />

                                    ▪ Sales representative kami akan memberikan update penawaran beserta laporan hasil pengecekan / survey. <br />

                                    ▪ Kami akan menyampaikan via email dokumen Sales Confirmation yang merupakan dokumen yang menjelaskan layanan beserta syarat dan ketentuan yang berlaku dan yang telah disepakatan, dan pelanggan dapat mentandatangani serta melengkapidokumen-dokumen yang dibutuhkan serta mengirimkan kembali soft copry (hasil scan) kepada kami via email. <br />

                                    ▪ Kami akan mencetak tagihan/invoice registrasi/installasi/biaya survey dan mengirimkan kepada pelanggan, dan apabila pelanggan telah menerima, pelanggan dapat melakukan pembayaran sesuai nominal tagihan. <br />

                                    ▪ Apabila pelanggan telah berhasil melakukan pembayaran dan dapat mengkonfirmasi ke sales representative atau juga dapat melalui menu konfirmasi pembayaran berikut ini ….. <br />

                                    ▪ Apabila pembayaran atas tagihan/invoice registrasi/installasi/biaya survey telah kami terima, maka pelanggan otomatis akan dibuatkan ID PELANGGAN dan akan masuk ke dalam antrian pemasangan/installasi. <br />

                                    ▪ Proses Pemasangan dan Installasi sampai dengan aktif / Ready For Service (RFS) akan memakan waktu 1 minggu s/d 1 bulan, tergantung dari tingkat kesulitan dan jarak sesuai hasil survey. <br />

                                    ▪ Setelah selesai pemasangan, maka team teknis kami akan melaksanakan aktivasi layanan sesuai dengan layanan yang diambil dan melakukan pengetesan layanan. <br />

                                    ▪ Setelah Layanan aktiv pelanggan berhak mendapatkan masa percobaan layanan maksimal 7 s/d 14 hari sesuai syarat dan ketentuan layanan. <br />

                                    ▪ Setelah masa trial selesai dan layanan dapat diterima dengan baik maka selanjutnya pelanggan wajib untuk dapat menandatangani Berita Acara dan dapat menyerahkan kembali kepada kami. <br />

                                    ▪ Selamat selanjutnya Pelanggan dapat menikmati dan menggunakan layanan kami dengan fitur sesuai dengan layanan yang diambil. <br />
                                    </p>
                                </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </section>
            </main>
        <Footer />

    </>
  )
}
