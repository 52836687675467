import React from 'react'
import NavBar3 from '../../components/NavBar3'
import { Jumbotron } from './Jumbotron'
import { Layanan } from './Layanan'
import { FaqSection } from './FaqSection'
import { OfficeLocation } from './OfficeLocation'
import Footer  from '../welcome/Footer'

export const Bantuan = () => {
  return (
    <div>
        <NavBar3 />
        <Jumbotron />
        <Layanan />
        <FaqSection />
        <OfficeLocation />
        <Footer />
    </div>
  )
}
