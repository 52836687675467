import React from 'react'

import './Jumbotron.css'
export const Jumbotron = () => {
  return (
    <div id='jumbotron'>
        <p id='title' className='h1'>Manage Service</p>
    </div>
  )
}
