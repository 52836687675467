import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './App2.css';
import Welcome from './pages/welcome/Welcome';
import useScript from './hooks/useScript';
import { MyRoutes } from './MyRoutes';
import { AuthProvider } from './contexts/auth';
import { MyRoutes2 } from './MyRoutes2';


function App() {
  useEffect(() => {
    
  }, [])

  return (
    <AuthProvider>
      {/* <MyRoutes /> */}
      <MyRoutes2 />
    </AuthProvider>
  );
}

export default App;
